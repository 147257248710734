import { Card, CardBody, Table } from "reactstrap";
import React, { useCallback, useEffect, useState } from "react";
import BasicPagination from "src/components/custom/pagination";
import CustomerCard from "./CustomerCard";
import TextInput from "src/components/forms/TextInput";
import { debounce } from "src/helper/commonHelp";
import Loader from "src/layouts/loader/Loader";
import NoDataFound2 from "src/components/custom/NoDataFound2";
import { statusOptions } from "src/constants/formPicker";
import SelectInput from "src/components/forms/SelectInput";
import useCustomerData from "./hooks/useCustomerData";
import NoContactFound from "src/assets/images/noDataFound/NoContactFound.png";

const Customers = () => {
  const [rowPerPage, setRowPerPage] = useState(20);
  const [page, setPage] = useState(1);
  const [searchUser, setSearchUser] = useState("");
  const [status, setStatus] = useState("");

  const { fetchData, CustomerData, allCustomersCount, isLoading } =
    useCustomerData();

  useEffect(() => {
    fetchData(searchUser, status, page, rowPerPage);
  }, // eslint-disable-next-line
  [page, rowPerPage, status]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const userTxtChangeDebounce = useCallback(
    debounce((txtval) => {
      fetchData(txtval, status, page, rowPerPage);
    }, 1000),
    []
  );

  return (
    <>
      <Card>
        <CardBody>
          <div className="table-heading d-flex align-items-center justify-content-between w-50">
            <div className="d-md-flex d-block  gap-3 align-item-center mb-3 w-100">
              <div className="" style={{ width: "40%" }}>
                <TextInput
                  type="text"
                  name="name"
                  value={searchUser}
                  onChange={(e) => {
                    setSearchUser(e.target.value);
                    userTxtChangeDebounce(e.target.value);
                  }}
                  onBlur={""}
                  variant="outlined"
                  placeholder="Search User"
                  label="Search User"
                  size={"small"}
                />
              </div>
              <div className="" style={{ width: "40%" }}>
                <SelectInput
                  name="status"
                  label="Status"
                  options={statusOptions}
                  selectedValue={status}
                  onChange={(e) => {
                    setPage(1);
                    setStatus(e.target.value);
                  }}
                  size={"small"}
                />
              </div>
            </div>
            <div className="d-flex"></div>
          </div>
          <div className="table-responsive">
            <Table className="align-middle">
              <thead>
                <tr>
                  <th>Sr.No.</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Plan</th>
                  <th>Amount</th>
                  <th>Payment Status</th>
                  <th>Created At</th>
                  <th style={{ textAlign: "center" }}>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              {isLoading ? (
                <Loader borderWidth={'4px'} />
              ) : CustomerData && CustomerData?.length > 0 ? (
                <tbody>
                  {CustomerData?.map((customer, index) => (
                    <CustomerCard
                    key={index}
                      customer={customer}
                      index={index}
                      fetchData={() => {
                        fetchData(searchUser, status, page, rowPerPage);
                      }}
                      page={page}
                      rowPerPage={rowPerPage}
                    />
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan="9">
                    <NoDataFound2 width={"180px"} text="No Data Found!" image={NoContactFound} />
                    </td>
                  </tr>
                </tbody>
              )}
            </Table>
          </div>
          <div className="mt-2 pagination-position mb-3">
            <BasicPagination
              count={Math.ceil(allCustomersCount / rowPerPage)}
              currentPage={page}
              onChange={handlePageChange}
              rowPerPage={rowPerPage}
              onRowChange={(e) => {
                setRowPerPage(e.target.value);
                setPage(1);
              }}
              totalCount={allCustomersCount}
            />
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default Customers;
