import * as React from 'react';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/material';
import { Button } from 'reactstrap';
import styles from './DeleteModal.module.css'
import { RxCross2 } from "react-icons/rx";
import deleteIcon from '../../assets/images/modal/trash.svg'

export default function DeleteModal({ closeModal, handleDelete, isDeleting=false, text, open, type,title,warning}) {

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={styles.delete_modal}
    >
      <Box sx={style} className={styles.delete_wrap}>
        <div className={styles.modal_wrap + " modal_box position-relative"} >
       
          <img src={deleteIcon} className={styles.modal_icon} />
          <h3>Are You Sure </h3>
         
         {title &&  <p>{title}</p>}

         {warning ?<p className={styles.warning_text}><b>Warning:</b> {text} </p>  : <p>You want to {type ? type : "delete"} this {text} ?</p> }

          <div className="modal-btn mt-3 text-end d-flex gap-2">
          <Button style={{marginLeft:"8px"}} onClick={handleDelete}  className={styles.delete_btn} disabled={isDeleting}>
              {isDeleting ? type ? 'Unmerging' : 'Deleting' : type ? 'Unmerge' : 'Delete'}  

            </Button>
            <Button  onClick={closeModal} className={styles.delete_btn + " " + styles.edit_btn}>
              Cancel
            </Button>
          </div>
          <div className={styles.close_modal}>
              <RxCross2 onClick={closeModal} />
          </div>
        </div>
      </Box>
    </Modal>
  );
}
