import * as React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

const ProgressBar = ({color, value}) => {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress 
        variant="determinate"
        value={value}
        sx={{
            height: 8, // Increase the height
            backgroundColor: "#EAF0F6", // Change the background color of the track
            '& .MuiLinearProgress-bar': {
            backgroundColor: color || '#3f51b5', // Change the color of the bar
            },
        }}
      />
    </Box>
  );
}

export default ProgressBar;
