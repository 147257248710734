import React, { useState, useRef, useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { MenuItem, FormControl, TextField, Popover, ClickAwayListener, InputAdornment, styled } from '@mui/material';

// Custom styled MenuItem to highlight selected item
const StyledMenuItem = styled(MenuItem)(({ theme, selected }) => ({
  backgroundColor: selected ? "#E2ECF8" : 'transparent',
  '&:hover': { backgroundColor: theme.palette.action.hover },
}));

// Custom styled TextField for search input
const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    padding: theme.spacing(0.5, 1), // Adjust padding to reduce height
    height: 33, // Set fixed height
    background: "#fff",
    border: "1.32px solid #CBD6E2",
    color: "#9EA2AA",
  },
  '& .MuiInputBase-input': {
    padding: theme.spacing(0, 1), // Adjust padding inside input
    fontSize: "14px",
    marginTop: "1px",
    color: "#9EA2AA",
  },
  '& .MuiOutlinedInput-root': { borderRadius: theme.shape.borderRadius },
  '& .MuiInputBase-input::placeholder': {
    color: 'black',
  },
}));

const CountryCode = ({ options, label, size, selectedValue, onChange, placeholder, noOptionText, disabled, customClass }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [filteredOptions, setFilterOptions] = useState(options || []);
  const selectedOptionRef = useRef(null);

  // Handle opening the Popover
  const handleClick = (event) => {
    if (!disabled) {
      setAnchorEl(event.currentTarget);
      setOpen(true);
    }
  };

  // Handle closing the Popover
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
    setSearchTerm(''); // Clear search term when closing
    setFilterOptions(options);
  };

  // Update search term
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    if (event.target.value) {
      const filterOptions = options.filter((option) => {
        const lowerSearchTerm = event.target.value.toLowerCase().trim();
        const lowerName = option.name.toLowerCase();
        const lowerShortCode = option.shortCode.toLowerCase();
        const lowerCode = option.code;
        const isMatch = lowerName.includes(lowerSearchTerm) 
        || lowerShortCode.includes(lowerSearchTerm)
          || lowerCode.includes(lowerSearchTerm);
        return isMatch;
      });
      setFilterOptions(filterOptions);
    } else {
      setFilterOptions(options);
    }
  };

  // Handle selecting an option
  const handleSelectChange = (code) => {
    onChange(code); // Pass the code of the selected country
    handleClose();
  };

  // Find the option for the selected value
  const selectedOption = options.find(option => option.code === selectedValue);
  const selectedDisplay = selectedOption ? `${selectedOption.shortCode}` : '';

 // Scroll to the selected option when the Popover opens
 useEffect(() => {
  if (open) {
    // Delay the scrolling to ensure elements are rendered
    setTimeout(() => {
      if (selectedOptionRef.current) {
        selectedOptionRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 100); // Adjust delay if needed
  }
}, [open, selectedValue]);

  return (
    <FormControl variant="outlined" fullWidth>
      <TextField
        label={label}
        value={selectedDisplay} // Display the shortCode of the selected value
        onClick={handleClick}
        autoComplete="off"
        readOnly
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <ArrowDropDownIcon />
            </InputAdornment>
          ),
          placeholder: placeholder,
        }}
        size={size || 'small'}
        variant="outlined"
        fullWidth
        style={{ color: "#000" }}
        disabled={disabled}
        className={customClass}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        style={{ marginTop: 14 }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div style={{ minWidth: 200, border: "2px solid #CBD6E2", borderRadius: "4px" }}>
            <div style={{ background: "#EAF0F6", padding: "7px 10px" }}>
              <CustomTextField
                size="small"
                placeholder="Search"
                fullWidth
                onChange={handleSearchChange}
                value={searchTerm}
                InputProps={{ endAdornment: (<InputAdornment position="end"> <SearchIcon fontSize='small' color='#9EA2AA' /> </InputAdornment>) }}
                variant="outlined"
                autoComplete="off"
              />
            </div>
            <div style={{ maxHeight: 200, overflow: 'auto', padding: "8px 0px" }}>
              {filteredOptions.length > 0 ? (
                filteredOptions.map((option) => (
                  <StyledMenuItem
                    key={option.shortCode}
                    selected={option.code === selectedValue}
                    onClick={() => handleSelectChange(option.code)}
                    ref={option.code === selectedValue ? selectedOptionRef : null}
                    style={{ background: option.code === selectedValue ? "#E2ECF8" : "#fff", color: "#33475B", fontFamily: "Inter", fontSize: "14px", textAlign: "left" }}
                  >
                    <span><b>{option.shortCode}</b> {option.name} {option.code}</span>
                  </StyledMenuItem>
                ))
              ) : (
                <div style={{ textAlign: "center", color: "darkgray", fontWeight: "500", padding: "1rem" }}>
                  {noOptionText || "No Options"}
                </div>
              )}
            </div>
          </div>
        </ClickAwayListener>
      </Popover>
    </FormControl>
  );
};

export default CountryCode;
