import React from "react";
import "./loader.scss";
import { Spinner } from "reactstrap";

const Loader = ({borderWidth}) => (
  <div className="fallback-spinner">
    <div className="loading">
      <Spinner color="primary" style={borderWidth ? {borderWidth: `${borderWidth}`} : {}} />
    </div>
  </div>
);
export default Loader;
