import React, { useState } from "react";
import { Label, FormGroup, Input } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import Axios from "../../../utils/axios";
import { useDispatch } from "react-redux";
import { setCredentials } from "../../../store/ContractAuthSlice";
import styles from "../../auth/LogIn.module.css";
import user2 from "../../../assets/images/logos/PropertyJinni-Logo.png";
import Toast from "../../../components/custom/Toast";
import { ButtonLoader } from "../../../components/forms/ButtonLoader";
import PasswordInput from "../../../components/forms/PasswordInput";
import ErrorMsg from "../../../components/custom/ErrorMsg";
import TextInput from "../../../components/forms/TextInput";
import Cookies from "js-cookie";

const ContractLogin = () => {
    const initialValues = {
        email: "",
        password: "",
    };
    const [isLoading, setIsLoading] = useState(false);
    const emailRegex = /^[^\W_](?:[a-zA-Z0-9.!#$%&'*+/=?^`{|}~-]*[^\W_])?@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+){1,2}$/;

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required("Email is required.")
            .email("Email is invalid.")
            .test("custom", "Please enter a valid email address.", (value) => {
                return emailRegex.test(value);
            }),
        password: Yup.string()
            .min(6, "Password must be at least 6 characters.")
            .required("Password is required.")
            .test(
                "not-start-with-space",
                "Password cannot start with a space.",
                (value) => !value.startsWith(" ")
            )
            .matches(
                /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d@$.!%*#?&]/,
                "Password must contain at least one uppercase letter, one lowercase letter, and one digit."
            ),
    });
    const dispatch = useDispatch();

    const handleLogin = (fields, { setSubmitting, setErrors }) => {
        const data = {
            email: fields.email,
            password: fields.password,
        };

        setIsLoading(true);
        Axios.post(`/contracts/auth/login`, data).then((response) => {
            const expirationTime = 82800;
            Cookies.set("popupTime", expirationTime?.toString(), { expires: 86400 });
            dispatch(setCredentials(response?.data));
            setIsLoading(false);
        })
            .catch((err) => {
                Toast(err, "error");
                setErrors({ submit: err });
                setIsLoading(false);
            });
    };

    return (
        <>
            <div className={` ${styles.signup}`}>
                <div className="container">
                    <div className={styles.login_row + " row"}>
                        <div className={styles.col_md_7 + " col-md-7"}>
                            <div className={styles.child_row + " min-vh-100 d-grid gap-3 align-content-between"}
                            >
                                <ul className="list-unstyled d-grid d-lg-flex gap-3 align-items-center justify-content-center justify-content-lg-between mb-0 pt-3">
                                    <li>
                                        <img
                                            src={user2}
                                            alt="user"
                                            width="194px"
                                            className="img-fluid mx-auto d-block"
                                        />
                                    </li>

                                </ul>
                                <div className={`w-100 d-grid gap-4  ${styles.form_design}`}>
                                    <div className={`text-center ${styles.form_design_top_cont}`}>
                                        <h3>Welcome Back</h3>
                                        <p>Login into your account</p>
                                    </div>
                                    <div className={` ${styles.form_design_bottom_form}`}>
                                        <Formik
                                            initialValues={initialValues}
                                            validationSchema={validationSchema}
                                            onSubmit={handleLogin}
                                            render={({ values, errors, touched, handleChange }) => (
                                                <Form className="d-grid gap-3">
                                                    <FormGroup>
                                                        <TextInput
                                                            customClass={styles.custom_input + " w-100"}
                                                            type="text"
                                                            name="email"
                                                            value={values.email}
                                                            onChange={handleChange}
                                                            // variant="standard"
                                                            size="small"
                                                            placeholder="Email"
                                                        // label="Email"
                                                        />
                                                        <ErrorMsg hasError={touched.email && errors.email}>
                                                            {errors.email}
                                                        </ErrorMsg>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <PasswordInput
                                                            customClass={
                                                                styles.custom_password +
                                                                " w-100 " +
                                                                styles.mypassword
                                                            }
                                                            type="password"
                                                            name="password"
                                                            value={values.password}
                                                            onChange={handleChange}
                                                            // variant="standard"
                                                            size="small"
                                                            placeholder="Password"
                                                        // label="Password"
                                                        />
                                                        <ErrorMsg
                                                            hasError={touched.password && errors.password}
                                                        >
                                                            {errors.password}
                                                        </ErrorMsg>
                                                    </FormGroup>

                                                    <FormGroup
                                                        className={`form-check ps-0 d-flex ${styles.form_check}`}
                                                        inline
                                                    >
                                                        <Label className="" check>
                                                            <Input
                                                                type="checkbox"
                                                                className="shadow-none d-none"
                                                            />
                                                            <div className="d-flex gap-3 align-item-center">
                                                                <div
                                                                    className={` ${styles.toggle_design}`}
                                                                ></div>
                                                                Remember me
                                                            </div>
                                                        </Label>
                                                        <Link
                                                            className="ms-auto text-decoration-none"
                                                            to="/auth/forgotPwd"
                                                        >
                                                            <small>Recover Password</small>
                                                        </Link>
                                                    </FormGroup>
                                                    {errors.submit && (
                                                        <div
                                                            style={{ display: "block" }}
                                                            className="invalid-feedback mb-0 mt-0"
                                                        >
                                                            {errors.submit}
                                                        </div>
                                                    )}
                                                    <FormGroup
                                                        className={` ${styles.button_with_forget}`}
                                                    >
                                                        <ButtonLoader
                                                            type="submit"
                                                            color="primary"
                                                            className={`shadow-none`}
                                                            isLoading={isLoading}
                                                            buttonName={"Login"}
                                                            loaderColor={"white"}
                                                        >
                                                            Login
                                                        </ButtonLoader>
                                                    </FormGroup>
                                                </Form>
                                            )}
                                        />
                                    </div>
                                </div>
                                <div></div>
                            </div>
                        </div>
                        <div className={styles.col_md_5 + " col-md-5"}>
                            <div
                                className={`min-vh-100 pb-5 p-3 p-md-4 d-grid align-items-end  ${styles.right_side_cont}`}
                            >
                                <div className={` ${styles.box_design}`}>
                                    <button className="px-4">www.propertyjinni.co.uk</button>
                                    <p>
                                        Our vision is to equip UK real estate agencies with the
                                        tools and resources to generate more leads, close more
                                        deals, and contribute to a thriving property market.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContractLogin;
