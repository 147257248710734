import {
  contactCategory,
  leadStatusOption,
  priorityList,
} from "src/constants/formPicker";

export const debounce = (func, timeout = 800) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export const getLabelByValue = (type, value) => {
  let data;
  if (type === "lead") {
    data = contactCategory;
  } else if (type === "leadStatus") {
    data = leadStatusOption;
  } else if (type === "leadPriority") {
    data = priorityList;
  }
  const found = data.find((item) => item.value === value);
  return found ? found.label : null;
};

export const getCategoryObject = (array) => {
  return array?.map((item) => {
    const label = item
      ?.split("-")
      ?.map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1))
      ?.join(" ");
    return { label, value: item };
  });
};


export const getCategoryDisplayName = (val) => {
    const label = val
      ?.split("-")
      ?.map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1))
      ?.join(" ");
    return label;

};