import React, { useEffect, useState, useRef } from "react";
import Popper from "@mui/material/Popper";
import Style from "./AccountSetup2.module.css";
import TextInput from "src/components/forms/TextInput";
import PhoneNumberWithCountryCode from "src/components/forms/PhoneNumberWithCountryCode";
import { countryCodes } from "src/constants/formPicker";
import CountryCode from "src/components/forms/CountriesCode";

const DirectorForm = ({ index, directors, errors, touched, handleChange, closeError }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverContent, setPopoverContent] = useState("");
  const [currentField, setCurrentField] = useState(null);
  const popperRef = useRef(null); // Ref for Popper container

  // Function to handle focus events
  const handleFocus = (event, errorMessage, fieldName) => {
    setAnchorEl(event.currentTarget);
    setPopoverContent(errorMessage);
    setCurrentField(fieldName);
  };

  // Function to handle closing the popover
  const handleClose = () => {
    setAnchorEl(null);
    setPopoverContent("");
    setCurrentField(null);
  };

  const open = Boolean(anchorEl);


  // Effect to handle clicks outside to close the Popper
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popperRef.current && !popperRef.current.contains(event.target) &&
          !document.querySelector(`input[name^="directors"]`).contains(event.target)) {
        handleClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
  
    if (currentField !== null && errors.directors) {
      const directorErrors = errors.directors[index];
  
      if (directorErrors) {
        const errorMessage = directorErrors[currentField];
  
        if (errorMessage) {
          const element = document.querySelector(`[name="directors[${index}].${currentField}"]`);
          if (element) {
            handleFocus({ currentTarget: element }, errorMessage, currentField);
          }
        } else {
          handleClose();
        }
      } else {
        handleClose();
        closeError();
      }
    } else if (!anchorEl && errors.directors) {
      // Show the Popper for the first error if no field is focused
      const firstErrorIndex = errors.directors.findIndex((error) => error && Object.keys(error).length > 0);
  
      if (firstErrorIndex !== -1) {
        const firstErrorField = Object.keys(errors.directors[firstErrorIndex])[0];
        const errorMessage = errors.directors[firstErrorIndex][firstErrorField];
        const element = document.querySelector(`[name="directors[${firstErrorIndex}].${firstErrorField}"]`);
  
        if (element) {
          handleFocus({ currentTarget: element }, errorMessage, firstErrorField);
        }
      }
    } else {
      handleClose();
    }
  }, [touched, errors]);
  
  return (
    <>
      {directors?.map((director, directorIndex) => (
        <tr key={directorIndex} className={Style.table_row}>
          <td>
            <div className={(errors.directors?.[directorIndex]?.name && touched.directors?.[directorIndex]?.name) ? Style.error_border : ""}>
              <TextInput
                customClass={Style.custom_textfield + " w-100"}
                type="text"
                name={`directors[${directorIndex}].name`}
                value={director.name}
                onChange={(e) => {
                  handleChange(e);
                  // Close the Popper if there's no error for this field
                  if (!errors.directors?.[directorIndex]?.name) {
                    handleClose();
                  }
                }}
                onFocus={(e) => errors.directors?.[directorIndex]?.name && touched.directors?.[directorIndex]?.name && handleFocus(e, errors.directors[directorIndex].name, 'name')}
                variant="outlined"
                size={"small"}
              />
            </div>
          </td>
          <td>
            <div className={(errors.directors?.[directorIndex]?.email && touched.directors?.[directorIndex]?.email) ? Style.error_border : ""}>
              <TextInput
                customClass={Style.custom_textfield + " w-100"}
                type="text"
                name={`directors[${directorIndex}].email`}
                value={director.email}
                onChange={(e) => {
                  handleChange(e);
                  // Close the Popper if there's no error for this field
                  setPopoverContent(errors.directors?.[directorIndex]?.email);
                  if (!errors.directors?.[directorIndex]?.email) {
                    handleClose();
                  }
                }}
                onFocus={(e) => errors.directors?.[directorIndex]?.email && touched.directors?.[directorIndex]?.email && handleFocus(e, errors.directors[directorIndex].email, 'email')}
                variant="outlined"
                size={"small"}
              />
            </div>
          </td>
          <td>
            <div className={(errors.directors?.[directorIndex]?.phone && touched.directors?.[directorIndex]?.phone) ? Style.error_border : ""}>
              <div className="d-flex align-items-center">
                <div style={{ width:"35%"}}>
                  <CountryCode
                    customClass={Style.custom_textfield + " w-100"}
                    name={`directors[${directorIndex}].countryCode`}
                    placeholder={"Country Code"}
                    options={countryCodes}
                    selectedValue={director.countryCode}
                    noOptionText={"No Country Code found."} 
                    onChange={(e) => {
                      handleChange(e);
                      if (!errors.directors?.[directorIndex]?.countryCode) {
                        handleClose();
                      }
                    }}
                    onFocus={(e) => errors.directors?.[directorIndex]?.phone && touched.directors?.[directorIndex]?.phone && handleFocus(e, errors.directors?.[directorIndex]?.phone, 'phone')}

                  />
                </div>
                <div style={{width:"100%"}}>
                  <PhoneNumberWithCountryCode
                    customClass={Style.custom_textfield + " w-100"}
                    countryCode={director.countryCode}
                    name={`directors[${directorIndex}].phone`}
                    value={director.phone}
                    variant="outlined"
                    placeholder="Phone"
                    size={"small"}
                    onChange={(e) => {
                      handleChange(e);
                      if (!errors.directors?.[directorIndex]?.phone) {
                        handleClose();
                      }
                    }}
                    onFocus={(e) => errors.directors?.[directorIndex]?.phone && touched.directors?.[directorIndex]?.phone && handleFocus(e, errors.directors?.[directorIndex]?.phone, 'phone')}
                  />
                </div>
              </div>
              {/* <TextInput
                customClass={Style.custom_textfield + " w-100"}
                type="text"
                name={`directors[${directorIndex}].phone`}
                value={director.phone}
                onChange={(e) => {
                  handleChange(e);
                  // Close the Popper if there's no error for this field
                  if (!errors.directors?.[directorIndex]?.phone && !touched.directors?.[directorIndex]?.phone) {
                    handleClose();
                  }
                }}
                onFocus={(e) => errors.directors?.[directorIndex]?.phone && touched.directors?.[directorIndex]?.phone && handleFocus(e, errors.directors[directorIndex].phone, 'phone')}
                variant="outlined"
                size={"small"}
              /> */}
            </div>
          </td>
          <Popper
            open={open}
            anchorEl={anchorEl}
            placement="bottom-start"
            onClose={handleClose}
            style={{ zIndex: 1300 }} // Ensure it displays above other elements
            ref={popperRef} // Assign ref to Popper
            sx={{
              background:"#fff",
              boxShadow: "-3px -3px 5px -2px rgba(0,0,0,0.1)",
              border: "1px solid #EAEAEA",
              padding:"1rem",
              color:"red",
              marginTop:"15px !important",
              borderRadius:"2px",
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right:"50%",
                borderTop: "1px solid #EAEAEA",
                borderLeft: "1px solid #EAEAEA",
                background: "#fff",
                width: 16,
                height: 16,
                transform: "translateY(-50%) rotate(45deg)",
                boxShadow: "-3px -3px 5px -2px rgba(0,0,0,0.1)",
              }
            }}
          >
            <div className={Style.custom_popper}>
              {popoverContent}
            </div>
          </Popper>
        </tr>
      ))}
    </>
  );
};

export default DirectorForm;
