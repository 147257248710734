import { Navigate, Outlet, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const ContractAuthGuard = ({ children }) => {
    const location = useLocation();
    const { pathname, search } = location;
    const isLoggedin = useSelector((state) => state.contract.isLoggedin);

    if (!isLoggedin) {
        if ((pathname === '/livechat') && search) {
            return <Navigate to={`/eSignature/login?redirectUrl=${pathname}${search}`} />;
        }
        else {
            return <Navigate to="/eSignature/login" />;
        }

    }

    return children ? children : <Outlet />;
};

ContractAuthGuard.propTypes = {
    children: PropTypes.node,
};

export default ContractAuthGuard;
