import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const initialState = {
    contractUser: localStorage.getItem("contractUser") ? JSON.parse(localStorage.getItem("contractUser")) : null,
    contractToken: null,
    isLoggedin: Cookies.get("isLoggedin") || null,
};

const contractAuthSlice = createSlice({
    name: "contractAuth",
    initialState,
    reducers: {
        setCredentials: (state, action) => {
            const usrInfo = action.payload.data;
 
            const usrObj = {
                name: usrInfo.name,
                profilePic: usrInfo.avatar,
                _id: usrInfo._id,
                email: usrInfo.email,
                role:usrInfo.userType
            };

            state.contractUser = usrObj;
            state.isLoggedin = true;
            localStorage.setItem("contractUser", JSON.stringify(usrObj));
            Cookies.set("isLoggedin", true, { expires: 7 });
        },

        updateUser: (state, action) => {
            const usrInfo = action.payload.data;
            const usrObj = {
                name: usrInfo.name,
                profilePic: usrInfo.avatar || usrInfo.profilePic,
                id: usrInfo.id,
                _id: usrInfo._id,
                email: usrInfo.email,
            };
            state.contractUser = usrObj;
            if (action.payload.contractToken) {
                state.contractToken = action.payload.contractToken;
            }
            localStorage.setItem("contractUser", JSON.stringify(usrObj));
        },

        updateIsLoggedIn: (state, action) => {
            state.isLoggedin = action.payload.isLoggedin;
        },

        logout: (state, action) => {
            state.contractUser = null;
            state.contractToken = null;
            state.isLoggedin = false;
            localStorage.clear();

            // Remove all the cookies
            Object.keys(Cookies.get()).forEach((cookieName) =>
                Cookies.remove(cookieName)
            );
        },
    },
});

export const selectContractUser = (state) => state.auth.contractUser;
export const selectContractToken = (state) => state.auth.contractToken;

export const { setCredentials, updateUser, logout, } = contractAuthSlice.actions;

export default contractAuthSlice.reducer;
