import React from "react";
import { DropdownItem } from "reactstrap";
import user1 from "src/assets/images/profileDrawer/user.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Styles from "../header/profile.module.css";
import setting_icon from "src/assets/images/profileDrawer/settings.svg";
import user_icon from "src/assets/images/profileDrawer/user.svg";

const ProfileDD = () => {
  const navigate = useNavigate();
  const contractUser = useSelector((state) => state.contract.contractUser);
  return (
    <div>
      <div className={Styles.profile_header + " d-flex gap-3 align-items-center"}>
        <img
          src={contractUser?.profilePic && contractUser?.profilePic !== "undefined"? contractUser?.profilePic: user1 }
          alt="user"
          className="rounded-circle"
          width="50"
          height="50"
        />
        <span>
          <h6 className="mb-0">{contractUser?.name}</h6>
          <small>{contractUser?.email} </small>
        </span>
      </div>
     
    </div>
  );
};

export default ProfileDD;
