import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import Axios from './axios';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { setCredentials } from 'src/store/AuthSlice';

const AuthGuard = ({ children }) => {
  const location = useLocation();
  const dispatch  = useDispatch();
  const { pathname, search } = location;
  const isLoggedin = useSelector((state) => state.auth.isLoggedin);
  const userObj = useSelector((state) => state.auth.user);
  const contractParam = new URLSearchParams(location.search).get('contract');

  useEffect(() => {
    if(contractParam){
      const data = {
        contract : contractParam
      };
      Axios.post(`/auth/login`, data).then((response) => {
          const expirationTime = 82800;
          Cookies.set("popupTime", expirationTime?.toString(), { expires: 86400 });
          dispatch(setCredentials(response?.data));
        })
        .catch((err) => {
        });
    }
  },[contractParam])
  

  if (!isLoggedin) {
    if ((pathname === '/livechat') && search) {  return <Navigate to={`/auth/login?redirectUrl=${pathname}${search}`} />; }
    else { return <Navigate to={`/auth/login${contractParam ? "?isContract=true" : ""}`} />; }
  }

  if (contractParam) { 
    if(userObj?.accountSetupFormStep >= 3){
      return <Navigate to="/dashboard" />;
    }
    else{
      return <Navigate to="/account-setup" />; }
    }
    

  if (userObj?.role === "companyadmin" && userObj?.accountSetupFormStep < 3 && pathname!== "/account-setup") {
    return <Navigate to="/account-setup" />;
  }

  return children ? children : <Outlet />;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
