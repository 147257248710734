import { lazy } from "react";
import Loadable from "../layouts/loader/Loadable";
import AuthGuard from "../utils/AuthGuard";
import LoginGuard from "../utils/LoginGuard";
import Role from "../utils/Roles";
import Customers from "../views/apps/customerManagement/Index.js";
import DemoSetup from "src/views/demo";
import Permission from "src/utils/Permissions";
import AccountSetup2 from "src/views/auth/accountSetup";
import BranchSelection from "src/views/auth/accountSetup/BranchSelection";
import ContractDetails from "src/views/contract/ContractDetails";
import ContractLayout from "src/layouts/contractLayout";
import ContractLogin from "src/views/contract/auth/Login";
import ContractLoginGuard from "src/utils/ContractLoginGuard";
import ContractAuthGuard from "src/utils/ContractAuthGuard";

const Subscriptions = Loadable(lazy(() => import("../views/subscriptions")));
const ConnectMediumPage = Loadable(
  lazy(() => import("../views/connectChat/ConnectMediumPage.jsx"))
);
const SubscriptionsList = Loadable(lazy(() => import("../views/apps/subscriptions/Index.js")));
const PaymentConfirmation = Loadable(lazy(() => import("../views/subscriptions/PaymentConfirmation.jsx")));
/****Layouts*****/

const FullLayout = Loadable(lazy(() => import("../layouts/FullLayout")));
const BlankLayout = Loadable(lazy(() => import("../layouts/BlankLayout")));
const PublicLayout = Loadable(lazy(() => import("../layouts/PolicyLayout")));
/***** Pages ****/

const General = Loadable(lazy(() => import("../views/dashboards/General")));

/***** Apps ****/
const AccountSetup = Loadable(lazy(() => import("../views/auth/accountSetup/AccountSetup")));
const LoginHistory = Loadable(lazy(() => import("../views/apps/loginHistory")));

const Email = Loadable(lazy(() => import("../views/apps/email/Email")));

const Taskbaord = Loadable(lazy(() => import("../views/apps/taskboard/Taskboard")));
const TaskBoardNew = Loadable(lazy(() => import("../views/apps/taskBordNew/index")));

const Todo = Loadable(lazy(() => import("../views/apps/todo/Todo")));
const Pricing = Loadable(lazy(() => import("../views/apps/pricing")));
const Contacts = Loadable(lazy(() => import("../views/apps/contacts")));
const Calendar = Loadable(
  lazy(() => import("../views/apps/calendar/CalendarApp"))
);
const NewCalendarApp = Loadable(lazy(() => import("../views/apps/newCalendar")));

const TicketList = Loadable(
  lazy(() => import("../views/apps/ticket/TicketList"))
);
const TicketDetail = Loadable(
  lazy(() => import("../views/apps/ticket/TicketDetail"))
);

const Profile = Loadable(lazy(() => import("../views/sample-pages/Profile")));
const LeadCalendar = Loadable(lazy(() => import("../views/leadCalendar")));

const Transactions = Loadable(
  lazy(() => import("../views/apps/transactions/Index"))
);
const ManageChat = Loadable(
  lazy(() => import("../views/apps/manageChat/Index"))
);

const Users = Loadable(lazy(() => import("../views/apps/users/Index")));
const CreateBranch=Loadable(lazy(()=>import("../views/apps/users/AddBranch")));


const AgencyAccess = Loadable(lazy(() => import("../views/apps/agencyAccess/Index")));
const AgecnyList = Loadable(lazy(() => import("../views/apps/agencyList/Index")));

const AgecnyLeadList = Loadable(lazy(() => import("../views/apps/agencyLeadList/Index")));
const AgecnyChatList = Loadable(lazy(() => import("../views/apps/agencyChatList/Index")));

/***** Auth Pages ****/
const Error = Loadable(lazy(() => import("../views/auth/Error")));

const LoginFormik = Loadable(lazy(() => import("../views/auth/LoginFormik")));
const Maintanance = Loadable(lazy(() => import("../views/auth/Maintanance")));

const RecoverPassword = Loadable(
  lazy(() => import("../views/auth/RecoverPassword"))
);

const RecoverPwd = Loadable(lazy(() => import("../views/auth/RecoverPwd")));
const TermsAndCondition = Loadable(
  lazy(() => import("../views/policy/TermsAndConditions.jsx"))
);
const PrivacyPolicy = Loadable(
  lazy(() => import("../views/policy/PrivacyPolicy.jsx"))
);

const UnsubscribeEmail = Loadable(lazy(() => import("../views/unsubscribe")));
const LiveChat = Loadable(lazy(() => import("../views/apps/liveChat")));
const Channels = Loadable(lazy(() => import("../views/sample-pages/channels")));
const ChatBotBuilder = Loadable(lazy(() => import("../views/chatbotBuilder")));
const SuperAdminContract = Loadable(lazy(() => import("../views/contract/superAdminContract")));
const Contract = Loadable(lazy(() => import("../views/contract")))
const CreateContract = Loadable(lazy(() => import("../views/contract/createContract")));
const ContractVerification = Loadable(lazy(() => import("../views/contract/verifyContract")))
const Success = Loadable(lazy(() => import("../views/contract/success")))
const PaymentFail = Loadable(lazy(() => import("../views/contract/failed")))

/*****Routes******/
const ThemeRoutes = [
  {
    path: "/",
    element: (<AuthGuard> <FullLayout /> </AuthGuard>),
    children: [
      {
        path: "",
        name: "general",
        exact: true,
        element: (
          <Permission permission="Dashboard">
            <General />
          </Permission>
        ),
      },
      {
        path: "dashboard",
        name: "general",
        exact: true,
        element: (
          <Permission permission="Dashboard">
            <General />
          </Permission>
        ),
      },
      {
        path: "taskboard",
        name: "taskbaord",
        exact: true,
        element: (
          <Permission permission="Taskbaord">
            <Taskbaord />
          </Permission>
        ),
      },
      {
        path: "taskboard-new",
        name: "taskbaordnew",
        exact: true,
        element: (
          <Permission permission="Taskbaord">
            <TaskBoardNew />
          </Permission>
        ),
      },
      {
        path: "taskboardnew",
        name: "taskbaordnewone",
        exact: true,
        element: (
          <Permission permission="Taskbaord">
            <TaskBoardNew />
          </Permission>
        ),
      },
      {
        path: "todo",
        name: "taskbaord",
        exact: true,
        element: (
          <Permission permission="Todo">
            <Todo />
          </Permission>
        ),
      },
      {
        path: "livechat",
        name: "Live chat",
        exact: true,
        element: (
          <Permission permission="Chat">
            <LiveChat />
          </Permission>
        ),
      },
      {
        path: "contacts",
        name: "contacts",
        exact: true,
        element: (
          <Permission permission="Contact">
            <Contacts />
          </Permission>
        ),
      },
      {
        path: "calendar2",
        name: "calendar2",
        exact: true,
        element: (
          <Permission permission="Calendar">
            <Calendar />
          </Permission>
        ),
      },
      {
        path: "calendar",
        name: "calendar",
        exact: true,
        element: (
          <Permission permission="Calendar">
            <NewCalendarApp />
          </Permission>
        ),
      },
      {
        path: "login-history",
        name: "login history",
        exact: true,
        element: (
          <Permission permission="LoginHistory">
            <LoginHistory />
          </Permission>
        ),
      },
      {
        path: "email",
        name: "email",
        exact: true,
        element: (
          <Permission permission="Email">
            <Email />
          </Permission>
        ),
      },
      {
        path: "agency-access",
        name: "agency access",
        exact: true,
        element: (
          <Permission permission="AgencyAccess">
            <AgencyAccess />
          </Permission>
        ),
      },
      {
        path: "contract",
        name: "agency details",
        exact: true,
        element: (
          <Permission permission="AgencyList">
             <SuperAdminContract/>
          </Permission>
        ),
      },
      {
        path: "agency-list",
        name: "agency details",
        exact: true,
        element: (
          <Permission permission="AgencyList">
            <AgecnyList />
          </Permission>
        ),
      },

      {
        path: "agency-lead-list",
        name: "agency lead list",
        exact: true,
        element: (
          <Permission permission="AgencyLeadList">
            <AgecnyLeadList />
          </Permission>
        ),
      },
      {
        path: "agency-chat-list",
        name: "agency chat list",
        exact: true,
        element: (
          <Permission permission="AgencyChatList">
            <AgecnyChatList />
          </Permission>
        ),
      },
      {
        path: "users",
        name: "users",
        exact: true,
        element: (
          <Permission permission="Users">
            <Users />
          </Permission>
        ),
      },
      {
        path: "users/create-branch",
        name: "users",
        exact: true,
        element: (
          <Permission permission="Users">
            <CreateBranch />
          </Permission>
        ),
      },
     
      {
        path: "transactions",
        name: "transactions",
        exact: true,
        element: (
          <Permission permission="Transaction">
            <Transactions />
          </Permission>
        ),
      },
      {
        path: "conversations",
        name: "conversations",
        exact: true,
        element: (
          <Role roles={["superadmin", "companyadmin"]}>
            <ManageChat />
          </Role>
        ),
      },
      {
        path: "subscription-list",
        name: "subscriptions",
        exact: true,
        element: (
          <Permission permission="Subscription">
            <SubscriptionsList />
          </Permission>
        ),
      },
      {
        path: "pricing",
        name: "pricing",
        exact: true,
        element: (
          <Permission permission="Price">
            <Pricing />
          </Permission>
        ),
      },
      {
        path: "leads",
        name: "leads",
        exact: true,
        element: (
          <Permission permission="Lead">
            <TicketList />
          </Permission>
        ),
      },
      {
        path: "leads/detail",
        name: "lead detail",
        exact: true,
        element: (
          <Permission permission="Lead">
            <TicketDetail />
          </Permission>
        ),
      },
      {
        path: "/channels",
        name: "channels",
        exact: true,
        element: (
          <Permission permission="Channels">
            <Channels />
          </Permission>
        ),
      },
      {
        path: "profile",
        name: "profile",
        exact: true,
        element: (
          <Role
            roles={["superadmin", "companyadmin", "companymanager", "agent"]}>
            <Profile />
          </Role>
        ),
      },
      {
        path: "customer-management",
        name: "Customer Management",
        exact: true,
        element: (
          <Permission permission="CustomerManagement">
            <Customers />
          </Permission>
        ),
      },
      {
        path: "chatbot-builder",
        name: "Chatbot Builder",
        exact: true,
        element: (
          <Permission permission="ChatbotBuilder">
            <ChatBotBuilder />
          </Permission>
        ),
      },
      {
        path: "setup-demo",
        name: "Demo Setup",
        exact: true,
        element: (
          <Role roles={["superadmin", "companyadmin", "companymanager", "agent"]}>
            <DemoSetup />
          </Role>
        ),
      },
    ],
  },

  {
    path: "auth",
    element: (
      <LoginGuard>
        <BlankLayout />
      </LoginGuard>
    ),
    children: [
      { path: "404", element: <Error /> },
      { path: "login", element: <LoginFormik /> },
      { path: "maintanance", element: <Maintanance /> },
      { path: "recoverpwd", element: <RecoverPassword /> },
      { path: "forgotPwd", element: <RecoverPwd /> },
    ],
  },

  {
    path: "eSignature",
    element: (<BlankLayout />),
    children: [
      {
        path: "login",
        element: (<ContractLoginGuard> <ContractLogin /> </ContractLoginGuard>),
      },
      {
        path: "dashboard", element: <ContractAuthGuard> <ContractLayout /> </ContractAuthGuard>,
        children: [{
          path: "contract",
          name: "contract",
          exact: true,
          element: (
            <Contract />
          )
        },
        {
          path: "/eSignature/dashboard/create-contract",
          name: "create-contract",
          exact: true,
          element: (
            <CreateContract />
          )
        },
        {
          path: "/eSignature/dashboard/contract-details/:id",
          name: "Contract details",
          exact: true,
          element: (
            <ContractDetails />
          )
        },]
      },

    ],
  },

  { path: "unsubscribe", element: <UnsubscribeEmail /> }, // for email template
  { path: "contract-verification", element: <ContractVerification /> },
  { path: "subscriptions/:plan", element: <Subscriptions /> },
  { path: "return", element: <PaymentConfirmation /> },
  { path: "success", element: <Success /> },
  { path: "failed", element: <PaymentFail /> },
  {
    path: "account-setup2",
    name: "account setup2",
    exact: true,
    element: (
      <AuthGuard>
        <Permission permission="AccountSetup">
          <AccountSetup />
        </Permission>
      </AuthGuard>
    ),
  },

  {
    path: "account-setup",
    name: "account setup",
    exact: false,
    element: (
      <AuthGuard>
        <Permission permission="AccountSetup">
          <AccountSetup2 />
        </Permission>
      </AuthGuard>
    ),
    children: [
      // { path: "/branch-selction", element: <BranchSelection /> },
      // { path: "step1", element: <AccountSetupStep1 /> },
      // { path: "step2", element: <AccountSetupStep2 /> },
      // { path: "step3", element: <AccountSetupStep3 /> },
    ],
  },

  {
    path: "connect-channels",
    name: "connect channels",
    exact: true,
    element: (
      <AuthGuard>
        <Permission permission="Channels">
          <ConnectMediumPage />
        </Permission>
      </AuthGuard>
    ),
  },
  { path: "lead-calendar", element: <LeadCalendar /> },
  {
    path: "policy",
    element: <PublicLayout />,
    children: [
      { path: "terms-of-service", element: <TermsAndCondition /> },
      { path: "privacypolicy", element: <PrivacyPolicy /> },
    ],
  },
  { path: "*", element: <Error /> },
];

export default ThemeRoutes;
