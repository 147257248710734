import Toast from 'src/components/custom/Toast';
import Axios from 'src/utils/axios';

const useAgencyData = () => {


    const getAgencyDetails = async (setAgency, setStep,setBranchCount) => {
        try {
            let response = await Axios.get(`/user/get-agency-full-details`);
            setAgency(response?.data?.data);
            setBranchCount(response?.data?.data?.branchCount);
            setStep(response?.data?.data?.formStep+1)
        } catch (error) {
            Toast(error, "error");
            return null;
        }
    }

    return {
        getAgencyDetails
    }
}

export default useAgencyData;
