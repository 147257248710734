import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
// import useAuth from '../hooks/useAuth';

const Permissions = ({ children, permission }) => {
  const userObj = useSelector((state) => state.auth.user);
  const userPermissions = userObj ? userObj.permissions : [];

  if (!permission || typeof permission === "undefined" || userObj?.role === "companyadmin") { return children; }

  const hasPermission = userPermissions?.includes(permission);

  return !hasPermission ? <Navigate to="/nopermission" /> : children;
};

export default Permissions;
