import { toast } from "react-toastify";
import { LOGOUTMESSAGE } from "src/constants/constants";

const Toast = (message, type, position = "bottom-left") => {
  const toastOptions = {
    position,
    autoClose: 2000,
    hideProgressBar: false,
    newestOnTop: false,
    closeOnClick: true,
    rtl: false,
    pauseOnFocusLoss: true,
    draggable: true,
    pauseOnHover: true,
    style: { fontSize: "13px" },
  };
  switch (type) {
    case "success":
      return toast.success(message, toastOptions);
    case "error":
      return message !== LOGOUTMESSAGE ? toast.error(message, { position }) : 0;
    case "warning":
      return message !== LOGOUTMESSAGE ? toast.warning(message, { position }) : 0;
    default:
      return toast.warning(message, { position });
  }
};
export default Toast;
