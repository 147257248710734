import { useState } from "react";
import Axios from "../../../utils/axios";
import Toast from "../../../components/custom/Toast";


const useHandleData = () => {

  const [contract, setContract] = useState(null);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [previewData, setPreviewData] = useState();
  const [previewLoading, setPreviewLoading] = useState();
  const [subscription, setSubscription] = useState();
  const [transactions, setTransactions] = useState([])


  const fetchData = async (
    searchUser,
    searchType,
    status,
    page,
    rowPerPage,
  ) => {
    setIsLoading(true);
    try {
      const response = await Axios.get(`/contracts/listing/get-contract-sign?page=${page}&limit=${rowPerPage}&name=${searchUser || ""}&type=${searchType === "all" ? "" : searchType || ""}&status=${status}`);
      setContract(response?.data?.data || []);
      setTotal(response?.data?.total || 0);
      setIsLoading(false);
    } catch (error) {
      Toast(error, "error");
      setIsLoading(false);
    }
  };

  const fetchDataAllForAdmin = async (
    searchUser,
    searchType,
    status,
    page,
    rowPerPage,
  ) => {
    setIsLoading(true);
    try {
      const response = await Axios.get(`/contracts/listing/get-all-contract-list?page=${page}&limit=${rowPerPage}&name=${searchUser || ""}&type=${searchType === "all" ? "" : searchType || ""}&status=${status}`);
      setContract(response?.data?.data || []);
      setTotal(response?.data?.total || 0);
      setIsLoading(false);
    } catch (error) {
      Toast(error, "error");
      setIsLoading(false);
    }
  };


  const fetchPreviewData = async (id) => {
    setPreviewLoading(true);
    try {
      const response = await Axios.get(`/contracts/listing/contract-detail?contractId=${id}`);
      setPreviewData(response?.data?.data || []);
      setSubscription(response?.data?.contactSubscription || {});
      setTransactions(response?.data?.contactTransactions || []);
      setPreviewLoading(false);
    } catch (error) {
      Toast(error, "error");
      setPreviewLoading(false);
    }
  }

  const updateNumberOfBranch = async (payload) => {
    setIsLoading(true);
    try {
      const response = await Axios.post(`/contracts/listing/increase-branch-count`,payload);
       
      setIsLoading(false);
    } catch (error) {
      Toast(error, "error");
      setIsLoading(false);
    }
  }

  return {
    fetchData,
    fetchDataAllForAdmin,
    contract,
    total,
    isLoading,
    fetchPreviewData,
    updateNumberOfBranch,
    previewData,
    previewLoading,
    setPreviewData,
    subscription,
    transactions
  };
};
export default useHandleData;
