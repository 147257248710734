import React, { useState } from "react";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";
import Downshift from "downshift";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./TagsInput.module.css";

export default function TagsInput({
  suggestions,
  tags,
  handleDelete,
  addChatTag,
  setTags,
  callApiToAddTag,
  rows,
  multiline,
  customClass,
  ...props
}) {
  const { placeholder, ...other } = props;

  const CustomChip = styled(Chip)({
    backgroundColor: "#4EA3FF42",
    color: "#00336B",
    fontFamily: "poppins",
    fontWeight: "500",
    fontSize: "12px",
    marginRight: "5px",
  });
  const CloseStyle = styled(CloseIcon)({
    fill: "#00336B", // Specify the color you want
    fontSize: "10px",
    width: "18px",
  });

  const [inputValue, setInputValue] = useState("");

  async function handleKeyDown(event) {
    if (event.key === "Enter") {
      setInputValue("");
      const trimmedValue = event.target.value.trim();

      if (!trimmedValue?.length) {
        // Input is empty, don't create a new chip
        return;
      }

      // Find the first suggestion whose tagName contains the entered value as a substring
      const matchingSuggestion = suggestions?.find(
        (suggestion) =>
          suggestion?.tagName?.toLowerCase() === trimmedValue?.toLowerCase()
      );

      if (matchingSuggestion) {
        // If there is a matching suggestion, select it directly
        if (!tags.includes(matchingSuggestion.tagName)) {
          setTags([...tags, trimmedValue]);
          // addChatTag(trimmedValue);
        }
      } else {
        // If there is no match, add a new item with the entered value
        // callApiToAddTag(trimmedValue);

        if (!tags.includes(trimmedValue)) {
          setTags([...tags, trimmedValue]);
          // addChatTag(trimmedValue);
        }
      }
    }

    if (tags?.length && !inputValue?.length && event.key === "Backspace") {
      handleDelete(tags[tags?.length - 1]);
    }
  }

  function handleChange(item) {
    setInputValue("");
    if (!tags?.includes(item.tagName)) {
      setTags([...tags, item.tagName]);
      addChatTag(item.tagName);
    }
  }

  return (
    <React.Fragment>
      <Downshift
        id="downshift-multiple"
        inputValue={inputValue}
        onChange={handleChange}
        tags={tags}
      >
        {({
          getInputProps,
          getItemProps,
          isOpen,
          inputValue: downshiftInputValue,
          highlightedIndex,
        }) => {
          const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
            onKeyDown: handleKeyDown,
            placeholder,
          });

          return (
            <div className={styles.demo}>
              <TextField
                InputProps={{
                  disableUnderline: true, // <== added this
                  startAdornment: tags?.map((item) => (
                    <CustomChip
                      key={item}
                      tabIndex={-1}
                      label={item}
                      onDelete={() => handleDelete(item)}
                      deleteIcon={<CloseStyle />}
                    />
                  )),

                  onBlur,
                  onChange: (event) => {
                    setInputValue(event.target.value);
                    onChange(event);
                  },
                  onFocus,
                }}
                {...other}
                {...inputProps}
                size="small"
                multiline={multiline ? true : false}
                minRows={rows}
                className={customClass}
              />

              {isOpen && inputValue !== "" && suggestions?.length > 0 && (
                <div className={styles.suggesstion_box}>
                  {suggestions
                    .filter(
                      (item) =>
                        typeof item === "object" &&
                        item.tagName &&
                        item.tagName
                          .toLowerCase()
                          .includes(downshiftInputValue.toLowerCase())
                    )
                    .map((item, index) => (
                      <div
                        {...getItemProps({ key: index, index, item })}
                        onClick={() => handleChange(item)}
                        style={{
                          backgroundColor: highlightedIndex === index ? "lightgray" : "white",
                          padding: "5px",
                        }}
                      >
                        {item.tagName}
                      </div>
                    ))}
                </div>
              )}
            </div>
          );
        }}
      </Downshift>
    </React.Fragment>
  );
}
