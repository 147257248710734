import React from 'react';
import { TextField, InputAdornment } from '@mui/material';

const PhoneNumberWithCountryCode = ({ countryCode, value, onChange, size, name, label,customClass }) => {
  return (
    <TextField
        className={customClass}
        name={name}
        label={label}
        variant="outlined"
        fullWidth
        value={value}
        onChange={onChange}
        InputProps={{
            startAdornment: (
            <InputAdornment position="start" sx={{ fontSize: '0.75rem' }}>
                <span className="country-code" style={{fontSize: customClass ? "13px" :"15px", fontWeight:"600", marginTop:"3px"}}>{countryCode}</span>
            </InputAdornment>
            ),
        }}
        size={size || "small"}
        autoComplete='off'
        sx={{".MuiInputBase-colorPrimary":
            customClass ? {paddingLeft:"0px"} : {}
        }}
    />
  );
};

export default PhoneNumberWithCountryCode;

