import { Navigate, Outlet, useLocation, } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Roles } from './constants/roles';

const LoginGuard = ({ children }) => {
  
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const redirectUrl = searchParams.get('redirectUrl');
  const auth = useSelector((state) => state.auth);
  
  if (auth?.isLoggedin) {
    if(redirectUrl){
      return <Navigate to={redirectUrl} />;
    }
    else{
      if(auth.user.role===Roles.SUPER_ADMIN){
        return <Navigate to="/agency-list" />;
         
      }
      return <Navigate to="/dashboard" />;
    }
    
  }

  return children ? children : <Outlet />;
};

LoginGuard.propTypes = {
  children: PropTypes.node,
};

export default LoginGuard;
