import React from "react";
import { useSelector, } from "react-redux";
import { Navbar, NavbarBrand, } from "reactstrap";
import Style from "../policy/Header.module.css";
import Logo from "src/assets/images/header/logo.png";
import HeaderNavigation from "../header/headerNavigation";
import Styles from "../header/profile.module.css";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import * as Icon from "react-feather";

import logout_icon from "src/assets/images/profileDrawer/logout.svg";

import Exchange from "src/assets/images/icons/exchange.png";

import user1 from "src/assets/images/profileDrawer/user.svg";

import ProfileDD from "./ProfileDD";
import Axios from "src/utils/axios";
import { useDispatch } from "react-redux";
import { logout } from "src/store/ContractAuthSlice";


const Header = () => {


    const isDarkMode = useSelector((state) => state.customizer.isDark);
    const topbarColor = useSelector((state) => state.customizer.topbarBg);
    const contractUser = useSelector((state) => state.contract.contractUser);

    const dispatch = useDispatch()


    const handleLogout = async () => {
        const logoutRes = await Axios.post(`contracts/auth/logout`);

        if (logoutRes) {
            dispatch(logout());
            window.location.href = "/eSignature/login"
        }
    };

    return (
        <Navbar color={topbarColor} dark={!isDarkMode} light={isDarkMode} expand="lg" className="topbar w-100">

            <div className="d-flex align-items-center justify-content-between h-100 w-100">
                <NavbarBrand href="/eSignature/dashboard/contract" className={Style.border_right + " " + Style.mr_0 + " h-100"}>
                    <img style={{ height: "2.5rem", objectFit: "contain", padding: "0 10px", }} src={Logo} alt="" />
                </NavbarBrand>
            <UncontrolledDropdown className="mx-1 ">
                <DropdownToggle color={topbarColor} className={Style.dropdown_border + " p-1 gap-2"}>
                    <img
                        src={contractUser?.profilePic && contractUser?.profilePic !== "undefined" ? contractUser?.profilePic : user1}
                        alt="profile"
                        className="rounded-circle"
                        width="26"
                        height="26"
                        style={{ objectFit: "contain" }}
                    /> 
                    <Icon.ChevronDown size={19} style={{ marginLeft: "3px" }} />
                </DropdownToggle>
                <DropdownMenu className={Styles.custom_dd + " ddWidth py-0"}>
                    <ProfileDD />
                    <div className="py-2 ">
                        <DropdownItem className={Styles.alt + " " + Styles.drop_text} onClick={handleLogout}>
                            {localStorage?.getItem("switch") ? <><img src={Exchange} width={20} /> &nbsp; Switch Account </> : <> <img src={logout_icon} /> &nbsp; Log Out</>}
                        </DropdownItem>
                    </div>
                </DropdownMenu>
            </UncontrolledDropdown>
            </div>
        </Navbar>
    );
};

export default Header;
