import { Navigate, Outlet, useLocation, } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const ContractLoginGuard = ({ children }) => {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const redirectUrl = searchParams.get('redirectUrl');
    const contract = useSelector((state) => state.contract);

    if (contract?.isLoggedin) {
        if (redirectUrl) {
            return <Navigate to={redirectUrl} />;
        }
        else {

            return <Navigate to="/eSignature/dashboard/contract" />;
        }

    }

    return children ? children : <Outlet />;
};

ContractLoginGuard.propTypes = {
    children: PropTypes.node,
};

export default ContractLoginGuard;
