import { configureStore } from '@reduxjs/toolkit';
import CustomizerReducer from './customizer/CustomizerSlice';
import authReducer from './AuthSlice';
import contractReducer from './ContractAuthSlice';
import MessageSlice from './apps/chat/MessageSlice';
import EmailReducer from './apps/email/EmailSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    customizer: CustomizerReducer,
    messageSlice: MessageSlice,
    emailReducer: EmailReducer,
    contract: contractReducer,
  },
});

export default store;