export const API_HOST = process.env.REACT_APP_API_HOST;
export const STRIPE_SECRET_KEY = process.env.REACT_APP_STRIPE_SECRET_KEY;
export const API_BASE_URL = `${API_HOST}/api/v1`;
export const googleMapsApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
export const LOGOUTMESSAGE = "Unauthorized Access.";

export const getAppHost = () => {
    if (API_HOST.includes('pjdevcrm')) {
        return "pjdev"
    } else if (API_HOST.includes('devcrm')) {
        return "dev"
    } else if (API_HOST.includes('testcrm')) {
        return "devtest"
    } else {
        return "crm"
    }
}