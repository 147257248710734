import React, { createContext, useCallback, useState } from 'react';
import socketIOClient from 'socket.io-client';
export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [isSocketActive, setSocketState] = useState(false);

  const API_HOST = process.env.REACT_APP_API_HOST;

  const connectSocket = useCallback(() => {
    const socketInstance = socketIOClient(API_HOST, {
      // transports: ["websocket", "polling"],
      transports: ["websocket"],
      reconnection: true,
    });

    socketInstance.on("connect", () => {
      setSocketState(prev => true);
      console.log("Socket Connected");
    });

    socketInstance.on("disconnect", (reason) => {
      setSocketState(prev => false);
      console.log("Socket Disconnected, Reason - ", reason);
    });

    setSocket(socketInstance);
  },[]);

  const disconnectSocket = () => {
    if (socket) {
      socket.disconnect();
      setSocket(null);
    }
  };

  return (
    <SocketContext.Provider value={{ socket, isSocketActive, connectSocket, disconnectSocket }}>
      {children}
    </SocketContext.Provider>
  );
};