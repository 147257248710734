import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const SelectWithSearch = ({
  size,
  label,
  options,
  value,
  onChange,
  className,
  disabled,
  noOptionsText
}) => {
  const getOptionLabel = (option) => {
    if (typeof option === "object") {
      return option.label || "";
    }
    return option || "";
  };

  const handleChange = (event, newValue) => {
    const selectedValue =
      typeof newValue === "object" ? newValue?.value || "" : newValue || "";
    onChange(event, selectedValue);
  };

  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={options}
      getOptionLabel={getOptionLabel}
      renderInput={(params) => <TextField {...params} label={label} />}
      size={size}
      value={
        options?.find(
          (option) => option?.value === value 
        ) || null
      }
      onChange={handleChange}
      className={className}
      disabled={disabled || false}
      noOptionsText={noOptionsText || "No options"}
    />
  );
};

export default SelectWithSearch;
