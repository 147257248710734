import React, { useState } from "react";
import Style from "./AccountSetup2.module.css";
import BranchDetailCard from "./BranchDetailCard";
import AgencyWebsite from "./AgencyWebsiteForm";

const BranchDetails = ({ handleNext, branches, handleAgencyData, agency }) => {

  const [currentBranch, setCurrentBranch] = useState(0);
  const handleBranchSubmit = () => { setCurrentBranch((prev) => prev + 1)};
  const branchesArray = Array.from({ length: branches });
  const [website, setWebsite]  = useState(agency?.companyUrl || "");
  const [isError, setIsError] = useState(false);
  

  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ marginTop: "3rem", maxWidth: "60%", margin: "auto" }}
    >
      <div>
        <div className="mb-5" >
          <AgencyWebsite 
              handleWebsite={(val) => {setWebsite(val)}}
              website={website}
              isError={isError}
              agency={agency}
        /></div>
        <div>
          <h3 className={Style.question_heading + " mb-2"}>Add Branch Details</h3>
          <p className={Style.subHeading}>
            Set up your branches to manage locations and operations effectively.
          </p>
          {branchesArray.map((branch, index) => (
            <BranchDetailCard
              key={index}
              index={index}
              totalCount={branches}
              handleNext={handleNext}
              handleBranchSubmit={handleBranchSubmit}
              isDisabled={index !== currentBranch}
              currentBranch={currentBranch}
              handleAgencyData={handleAgencyData}
              agency={agency}
              branch={branch}
              handleOpenBranch={(val) => { setCurrentBranch(val); }}
              website={website}
              handleWebsite={() => {setIsError(true)}}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BranchDetails;
