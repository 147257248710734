import * as React from "react";

const NoDataFound2 = ({ text, width, minHeight, image, background }) => {
  return (
    <div
      className=" shadow-none"
      style={{
        minHeight: minHeight || "50vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
       ...(background && { background : background })
      }}
    >
      <div className=" text-center py-3 px-3">
        <img
          src={image || "/images/nodatafound.png"}
          width={width ? width : "150px"}
          className="img-fluid mb-3 "
          style={{ opacity: "1" }}
        />
        <h5 className="fw-bolder text-black mb-0" style={background ? {color: "rgb(192 190 190) !important"} : {}}>{text}</h5>
      </div>
    </div>
  );
};

export default NoDataFound2;
