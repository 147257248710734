import { useState } from "react";
import Axios from "src/utils/axios";
import Toast from "src/components/custom/Toast";

const useCustomerData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [CustomerData, setCustomerData] = useState(null);
  const [allCustomersCount, setAllCustomersCount] = useState(0);
  const [transactionList, setTransactionList] = useState([]);
  const [subscription, setSubscription] = useState({});

  const fetchData = async (searchName, status, page, rowPerPage) => {
    setIsLoading(true);
    try {
      const response = await Axios.get(
        `/user/get-customers?page=${page}&limit=${rowPerPage}&name=${
          searchName || ""
        }&status=${status}`
      );
      setCustomerData(response?.data?.data || []);
      setAllCustomersCount(response.data.total || 0);
    } catch (error) {
      Toast(error, "error");
    }
    finally{
      setIsLoading(false);
    }
  };

  const fetchTransactions = async (customerId) => {
    try {
      setIsLoading(true);
      const response = await Axios.get(
        `/subscription/get-transactions?user=${customerId}`
      );
      setTransactionList(response?.data?.data || []);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      Toast(error, "error");
    }
  };

  const fetchSubscription = async (customerId) => {
    try {
      setIsLoading(true);
      const response = await Axios.get(
        `/subscription/get-subscriptions?user=${customerId}`
      );
      setSubscription(response?.data?.data[0]);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      Toast(error, "error");
    }
  };

  return {
    fetchData,
    CustomerData,
    allCustomersCount,
    isLoading,
    fetchTransactions,
    fetchSubscription,
    transactionList,
    subscription,
  };
};
export default useCustomerData;
