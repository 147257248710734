import { NavLink, NavItem } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

const NavItemContainer = ({
  to,
  icon,
  title,
  className,
}) => {
  const navigate = useNavigate();
  return (
    <NavItem
      onClick={() => {
        navigate(to);
      }}
      className={className}
    >
      <NavLink tag={Link} to={to} className="gap-1 nav_link d-flex">
        <span className="sidebarIcon">
          <img src={icon}></img>
        </span>
        <span className="hide-mini w-100">
          <div className="d-flex align-items-center">
            <span>{title}</span>
          </div>
        </span>
      </NavLink>
    </NavItem>
  );
};
NavItemContainer.propTypes = {
  title: PropTypes.string,
  to: PropTypes.string,
  icon: PropTypes.node,
  className: PropTypes.string,
};

export default NavItemContainer;
