import React, { useState } from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function PasswordInput({
  customClass,
  placeholder,
  password,
  setPassword,
  name,
  onChange,
  onBlur,
  label,
  variant,
  size
}) {
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  return (
    <TextField
      id="outlined-basic"
      className={customClass}
      style={{ width: "100%" }}
      type={showPassword ? "text" : "password"}
      autoComplete="new-password"
      name={name}
      variant={variant ? variant : "outlined"}
      value={password}
      placeholder={placeholder ? placeholder : "Password"}
      onChange={onChange}
      onBlur={onBlur}
      label={label ? label : ""}
      size={size || ""}
      InputProps={{
        autoComplete: "off",
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handlePasswordToggle} edge="end">
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

export default PasswordInput;
