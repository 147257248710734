import React from "react";

import Style from "./AccountSetup2.module.css";
import TextInput from "src/components/forms/TextInput";
import { departmentRole, departmentType } from "src/constants/formPicker";
import SelectInput from "src/components/forms/SelectInput";
import { Box, Grid } from "@mui/material";
import ErrorMsg from "src/components/custom/ErrorMsg";

const BranchDetailForm = ({ errors, touched, values, handleChange, handleDetailNextButton }) => {

  return (
    <div>
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                    <div className={Style.input_design + " "}>
                    <div className="d-grid gap-2">
                        <label>Branch Name<span className="red_required_span">{" "} *{" "}</span> </label>
                        <TextInput
                        customClass={Style.input_custom + " w-100"}
                        type="text"
                        name={`branchName`}
                        value={values.branchName}
                        onChange={handleChange}
                        variant="outlined"
                        placeholder="Branch Name"
                        size={"small"}
                        />
                        <ErrorMsg hasError={touched.branchName && errors.branchName}>{errors.branchName} </ErrorMsg>
                    </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <div className={Style.input_custom + " "}>
                    <div className="d-grid gap-2">
                        <label>Branch Website </label>
                        <TextInput
                        customClass={" w-100"}
                        type="text"
                        name={`branchWebsite`}
                        value={values.branchWebsite}
                        onChange={handleChange}
                        variant="outlined"
                        placeholder="Branch Website"
                        size={"small"}
                        />
                        <ErrorMsg hasError={touched.branchWebsite && errors.branchWebsite}>{errors.branchWebsite} </ErrorMsg>
                    </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <div className="d-grid gap-2">
                    <label>
                        {" "}
                        Deals With{" "}
                        <span className="red_required_span"> *</span>
                    </label>
                    <SelectInput
                        name={`dealsWith`}
                        placeholder="Deals With"
                        size={"small"}
                        options={departmentType}
                        customClass={Style.custom_select}
                        selectedValue={values.dealsWith}
                        onChange={(value) => {
                        handleChange(`dealsWith`)(value);
                        }}
                    />
                    <ErrorMsg hasError={touched.dealsWith && errors.dealsWith}>{errors.dealsWith} </ErrorMsg>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <div className="d-grid gap-2">
                    <label> Type of Agency <span className="red_required_span"> * </span>
                    </label>
                    <SelectInput
                        name={`typeOfAgency`}
                        placeholder={"Type of Agency"}
                        size={"small"}
                        options={departmentRole}
                        customClass={Style.custom_select}
                        selectedValue={values.typeOfAgency}
                        onChange={(value) => {
                        handleChange(`typeOfAgency`)(value);
                        }}
                    />
                    <ErrorMsg hasError={touched.typeOfAgency && errors.typeOfAgency}>{errors.typeOfAgency} </ErrorMsg>
                    </div>
                </Grid>
            </Grid>
        </Box>
        {/* <button  type="button" className={Style.move_btn}  onClick={handleDetailNextButton}>Move to next step</button> */}
        </div>
  );
};

export default BranchDetailForm;
