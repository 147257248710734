import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import ErrorMsg from "src/components/custom/ErrorMsg";
import TextInput from "src/components/forms/TextInput";
import Style from "./AccountSetup2.module.css";
import { ButtonLoader } from "src/components/forms/ButtonLoader";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Axios from "src/utils/axios";
import Toast from "src/components/custom/Toast";
import { debounce } from "src/helper/commonHelp";

const AgencyWebsite = ({ handleNext, handleAgencyData, agency, handleWebsite, website,isError }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    companyWebsite: Yup.string()
      .test("is-valid-url", "Invalid URL format.", (value) => {
        if (!value) return false; // If value is empty, fail validation

        // Check if URL has www or not
        if (!/^(?:https?:\/\/)?(?:www\.)?[\w-]+(?:\.[a-z]{2,})(?:\.[a-z]{2,})?(?:\.[a-z]{2,})?(?:\.[a-z]{2,})?(?:\.[a-z]{2,})?$/i.test(value)) {
          return false;
        }
        return true; // Passes all validations
      })
      .required("Agency website is required."),
  });

  const formik = useFormik({
    initialValues: { companyWebsite: agency?.companyUrl || "" },
    validationSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      saveData(values);
    },
  });

  const saveData = async (values) => {
    setIsLoading(true);
    try {
      let response = await Axios.post(`/AccountSetup/create-company-url`, { ...values });
      handleAgencyData(response?.data?.data);
      handleNext();
    } catch (error) {
      Toast(error, "error");
      setIsLoading(false);
    }
  };

  const userTxtChangeDebounce = useCallback(
    debounce((val) => {
      formik.handleSubmit();
    }, 1000),
    []
  );

  useEffect(() => {

    if(isError){
      formik.handleSubmit();
    }

  },[isError])

  return (
    <>
      <div  >
        <div>
          <h3 className={Style.question_heading + " mb-4"}>What is your agency’s website?</h3>
          <div className={Style.branch_rd + " gap-2" } style={{width:"65%"}}>
            <label className={Style.input_label + " mb-3"}>
              Agency Website<span className="red_required_span"> * </span>
            </label>
            <TextInput
              customClass={Style.website_input_custom + " w-100"}
              type="text"
              name="companyWebsite"
              value={formik.values.companyWebsite}
              onChange={(e) => {
                formik.setFieldValue('companyWebsite', e.target.value)
                handleWebsite(e.target.value)
                userTxtChangeDebounce(e.target.value); 
              }}
              // variant="standard"
              placeholder="Agency Website"
              size={"small"}
            />
            <ErrorMsg hasError={formik.touched.companyWebsite && formik.errors.companyWebsite}>
              {formik.errors.companyWebsite}
            </ErrorMsg>
          </div>
          {/* <ButtonLoader
            onClickhandle={formik.handleSubmit}
            buttonName="Next"
            isLoading={isLoading}
            loaderColor={"white"}
            style={{
              marginLeft: "12px",
              paddingLeft: "12px",
              paddingRight: "12px",
            }}
            className={Style.submit_btn}
            icon={<KeyboardArrowRightIcon />}
          /> */}
        </div>
      </div>
    </>
  );
};

export default AgencyWebsite;
