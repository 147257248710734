import moment from "moment";
import React from "react";
import { Badge, UncontrolledTooltip } from "reactstrap";
import { Box, Drawer } from "@mui/material";
import CustomerDetails from "./CustomerDetails";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import Style from "./Customer.module.css";

const CustomerCard = ({ customer, index, fetchData, page, rowPerPage }) => {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 600 }}
      role="presentation"
    >
      <CustomerDetails
        onClose={toggleDrawer(anchor, false)}
        toggleDrawer={() => setState({ ...state, [anchor]: false })}
        fetchData={fetchData}
        customerId={customer?._id}
      />
    </Box>
  );

  return (
    <tr key={index + 1}>
      <td>{(page - 1) * rowPerPage + index + 1}</td>
      <td style={{ textTransform: "capitalize" }}>
        {customer?.name ? customer?.name : "NA"}
      </td>
      <td>{customer?.email}</td>
      <td>
        {customer?.subscription[0]?.plan
          ? customer?.subscription[0]?.plan
          : "NA"}
      </td>
      <td>
        £
        {customer?.subscription[0]?.amount
          ? customer?.subscription[0]?.amount
          : 0}
      </td>
      <td style={{ textTransform: "capitalize" }}>
        <Badge
          color={
            customer?.subscription[0]?.paymentStatus === "declined"
              ? "danger"
              : customer?.subscription[0]?.paymentStatus === "paid"
              ? "success"
              : customer?.subscription[0]?.paymentStatus === "pending"
              ? "warning"
              : "dark"
          }
        >
          {customer?.subscription[0]?.paymentStatus
            ? customer?.subscription[0]?.paymentStatus
            : "NA"}
        </Badge>
      </td>
      <td>{moment(customer?.createdAt).format("DD/MM/YYYY")}</td>
      <td style={{ textAlign: "center" }}>
        <span className={customer?.status === 1 ? Style.active : Style.cancel}>
          {customer?.status && customer?.status === 1 ? (
            <CheckCircleIcon />
          ) : customer?.status === 0 ? (
            <CancelIcon />
          ) : (
            ""
          )}
        </span>
      </td>
      <td style={{ textAlign: "center" }}>
        <i
          className="bi bi-eye cursor-pointer "
          id="TooltipExample1"
          onClick={toggleDrawer("right", true)}
        />
        <UncontrolledTooltip placement="left" target="TooltipExample1">
          View
        </UncontrolledTooltip>
        <Drawer
          anchor={"right"}
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
        >
          {list("right")}
        </Drawer>
      </td>
    </tr>
  );
};

export default CustomerCard;
