import axios from "axios";
import Cookies from "js-cookie";

import { API_BASE_URL } from "../constants/constants";
import { updateAuthorizedStatus } from "src/store/AuthSlice";
import store from "src/store/Store";

const Axios = axios.create({
  baseURL: API_BASE_URL,
  params: {
    apporigin: "web",
  },
  headers: {
    source: "web",
  },
});

Axios.interceptors.request.use((config) => {
  config.withCredentials = true;
  return config;
});

Axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      Object.keys(Cookies.get()).forEach((cookieName) =>
        Cookies.remove(cookieName)
      );
      store.dispatch(updateAuthorizedStatus({ isAuthorized: false }));
    }
    return Promise.reject(
      error.response.data.error ||
        error.response.data.message ||
        "Something went wrong"
    );
  }
);

export default Axios;
