import * as React from "react";
import { Pagination } from "@mui/material";
import Stack from "@mui/material/Stack";
import SelectInput from "../forms/SelectInput";
import { RowsPerPageList } from "../../constants/formPicker";

const BasicPagination = ({
  count,
  currentPage,
  onChange,
  rowPerPage,
  onRowChange,
  totalCount,
}) => {
  return (
    <>
      {totalCount > 10 && (
        <div style={{ width: "8%" }}>
          <SelectInput
            name="rowPerPage"
            label="Rows"
            customClass={""}
            options={RowsPerPageList}
            selectedValue={rowPerPage}
            onChange={onRowChange}
            size={"small"}
          />
        </div>
      )}
      {totalCount > rowPerPage && (
        <Stack spacing={2}>
          <Pagination
            count={count}
            color="primary"
            page={currentPage}
            onChange={onChange}
          />
        </Stack>
      )}
    </>
  );
};

export default BasicPagination;
