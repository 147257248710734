import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
// import useAuth from '../hooks/useAuth';

const Role = ({ children, roles }) => {
  const userObj = useSelector((state) => state.auth.user);

  if (!roles || typeof roles === "undefined" || userObj?.role === "companyadmin") {
    return children;
  }
  const hasFound = roles.filter((elm) => userObj?.role === elm);

  return !hasFound.length ? <Navigate to="/nopermission" /> : children;
};

export default Role;

/*
superadmin
companyadmin
companymanager
agent

*/
