import React, { useState, useEffect } from "react";
import { FieldArray, Form, Formik } from "formik";
import * as Yup from "yup";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import AddIcon from '@mui/icons-material/Add';
import { useSelector } from "react-redux";

import { ButtonLoader } from "src/components/forms/ButtonLoader";
import Style from "./AccountSetup2.module.css";
import { Table } from "reactstrap";
import DirectorForm from "./DirectorForm";
import Axios from "src/utils/axios";
import Toast from "src/components/custom/Toast";

const DirectorManagement = ({ handleNext, handleBranchValue, handleError, handleAgencyData, agency, closeError }) => {
  const userObj = useSelector((state) => state.auth.user);
  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    directors: [
      {
        name: userObj.name || "",
        email: userObj.email || "",
        phone: userObj.phone || "",
        countryCode:userObj.countryCode || "+44",
      },
      {
        name: "",
        email: "",
        phone: "",
        countryCode:"+44",
      },
    ],
  });

  const validationSchema = Yup.object().shape({
    directors: Yup.array().of(
      Yup.lazy((value) => {
        if (!value.name && !value.email && !value.phone) {
          return Yup.object().shape({});
        }
        return Yup.object().shape({
          name: Yup.string()
            .matches(/^[a-zA-Z ]+$/, "Name can only contain alphabetical characters.")
            .required("Name is required.")
            .test(
              "not-start-with-space",
              "Name cannot start with a space.",
              (value) => !value.startsWith(" ")
            ),
          email: Yup.string()
            .email("Invalid email format.")
            .required("Email is required.")
            .test("custom", "Please enter a valid email address.", (value) => {
              const parts = value.split("@");
              if (
                parts.length !== 2 ||
                /[^a-zA-Z0-9.-]/.test(parts[0]) ||
                /[^a-zA-Z0-9.-]/.test(parts[1])
              ) {
                return false;
              }

              if (parts.length === 2 && parts[1].split(".").length - 1 > 2) {
                return false;
              }
              return true;
            }),
          phone: Yup.string()
            .matches(/^\+?[0-9]\d{1,12}$/, "Invalid mobile number.")
            .min(10, "Phone number must be at least 10 characters.")
            .max(11, "Phone number cannot exceed 11 characters.")
            .required("Phone Number is required.")
            .test(
              "not-start-with-space",
              "Phone cannot start with a space.",
              (value) => {
                return !value || !value.startsWith(" ");
              }
            ),
            countryCode: Yup.string() .required("Country Code is required."),
        });
      })
    ),
  });

  const checkUniqueEmail = async (directors) => {
    const emails = directors.map(member => member.email);
    const uniqueEmails = new Set(emails);
    return emails.length === uniqueEmails.size;
  }

  const isFullyFilled = (director) => {
    return director.name && director.email && director.phone;
  };
  
  const handleSubmit = async (values) => {
    const filledDirectors = values.directors.filter(isFullyFilled);
  
    if (!checkUniqueEmail(filledDirectors)) {
      handleError("Each team member must have a unique email.");
    } else {
      setIsLoading(true);
      try {
        let response = await Axios.post(`/AccountSetup/create-director`, { directors: filledDirectors });
        handleAgencyData(response?.data?.data);
        handleNext();
      } catch (error) {
        Toast(error, "error");
        setIsLoading(false);
      }
    }
  };
  

  useEffect(() => {
    if (agency?.users) {
      const adminUsers = agency.users.filter(user => user?.userType === 'companyadmin');
      const directors = adminUsers.map(user => ({
        name: user.name || "",
        email: user.email || "",
        phone: user.phone || "",
        countryCode:user.countryCode || "",
      }));

      // If there are no admin users, fall back to the default values
      if (directors.length === 0) {
        setInitialValues({
          directors: [
            {
              name: userObj.name || "",
              email: userObj.email || "",
              phone: userObj.phone || "",
              countryCode: userObj.countryCode || "+44",
            },
            {
              name: "",
              email: "",
              phone: "",
              countryCode:"+44"
            },
          ],
        });
      } else {
        setInitialValues({
          directors: directors.length ? directors : [{ name: "", email: "", phone: "" }],
        });
      }
    }
  }, [agency?.users, userObj]);

  return (
    <div className="d-flex align-items-center " style={{ marginTop: "3rem", maxWidth: "80%", margin: "auto" }}>
      <div>
        <div className="w-50 mb-5">
          <h3 className={Style.question_heading + " mb-2"}>
            {" "}
            Add directors to set up your account together{" "}
          </h3>
          <p className={Style.subHeading}>
            Share data, assign contacts, and get more done together. You can
            also do this later, if you prefer.
          </p>
        </div>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, handleChange, setFieldValue, handleSubmit }) => (
            <Form>
              <FieldArray name="directors">
                {({ push: pushDirector, remove: removeDirector }) => (
                  <div>
                    <div className={Style?.custom_table + " table-responsive "}>
                      <Table className={" align-middle mb-0"} style={{ borderRadius: "5px" }}>
                        <thead className={Style.table_head}>
                          <tr>
                            <th>Full Name</th>
                            <th>Email Address</th>
                            <th>Phone Number</th>
                          </tr>
                        </thead>
                        <tbody>
                          <DirectorForm
                            directors={values.directors}
                            errors={errors}
                            touched={touched}
                            handleChange={handleChange}
                            closeError={closeError}
                          />
                        </tbody>
                      </Table>
                    </div>
                    <div className="" style={{ marginLeft: "10px" }}>
                        <button type="button" className={Style.add_btn} onClick={() => pushDirector({ name: "", email: "", phone: "",countryCode:"+44", })}>
                          <AddIcon sx={{ color: "#933EFF", fontSize: "20px", cursor: "pointer" }} />
                          <p>Add Row</p>
                        </button>
                      </div>
                    <div className="d-flex align-items-center mt-5">
                      <ButtonLoader
                        onClickhandle={handleSubmit}
                        isLoading={isLoading}
                        buttonName="Next"
                        loaderColor={"white"}
                        className={Style.next_btn}
                        icon={<KeyboardArrowRightIcon />}
                      ></ButtonLoader>

                    </div>
                  </div>
                )}
              </FieldArray>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default DirectorManagement;
