import { Roles } from "../../../utils/constants/roles";
import { useSelector } from "react-redux";

import Dashboard from "src/assets/images/header/Vector.svg";
import Chat from "src/assets/images/header/Chat.svg";
import Calendar from "src/assets/images/header/Calendar.svg";
import Taskboard from "src/assets/images/header/Task.svg";
import Inbox from "src/assets/images/header/Email.svg";
import Document from "src/assets/images/header/doc.svg";
import Contacts from "src/assets/images/header/Contact.svg";
import Tickets from "src/assets/images/header/Ticket.svg";
import Subscribe from "src/assets/images/header/subscribe.svg";
import Conversation from "src/assets/images/header/messanger.svg";
import Users from "src/assets/images/header/user.svg";
import Transactions from "src/assets/images/header/transaction.svg";
import Channels from "src/assets/images/header/social-media.svg";
import bot from "src/assets/images/header/bot.svg";
import Todo from "src/assets/images/todo/Todo.svg"


const SidebarData = () => {
  // const SidebarArr = [];
  const userObj = useSelector((state) => state.auth.user);

  // var role = userObj && userObj.role ? userObj.role : null;

  const allowedModules = userObj && userObj.permissions ? userObj.permissions : [];

  // if (
  //   role === Roles.COMPANY_ADMIN ||
  //   role === Roles.SUPER_ADMIN ||
  //   role === Roles.COMPANY_MANAGER ||
  //   role === Roles.AGENT
  // ) {
  //   SidebarArr.push(
  //     {
  //       title: "Dashboard",
  //       href: "/dashboard",
  //       id: 1,
  //       suffixColor: "bg-info text-dark-white",
  //       icon: Dashboard,
  //       collapisble: true,
  //     },
  //     {
  //       title: "Chats",
  //       href: "/livechat",
  //       icon: Chat,
  //       id: 2,
  //       collapisble: false,
  //     },
  //     {
  //       title: "Calendar ",
  //       href: "/calendar",
  //       icon: Calendar,
  //       id: 3,
  //       collapisble: false,
  //     },
  //     {
  //       title: "Leads",
  //       href: "/leads",
  //       icon: Tickets,
  //       id: 4,
  //       collapisble: true,
  //     },  
  //     {
  //       title: "Contacts",
  //       href: "/contacts",
  //       icon: Contacts,
  //       id: 5,
  //       collapisble: false,
  //     },
  //     {
  //       title: "Todo",
  //       href: "/todo",
  //       icon: Todo,
  //       id: 9,
  //       collapisble: false,
  //     },
  //     {
  //       title: "Taskboard",
  //       href: "/taskboard",
  //       icon: Taskboard,
  //       id: 11,
  //       collapisble: false,
  //     },
  //   );
  // }

  // if (role === Roles.SUPER_ADMIN || role === Roles.COMPANY_ADMIN || role === Roles.COMPANY_MANAGER){
  //   SidebarArr.push(
  //     {
  //       title: "Users",
  //       href: "/users",
  //       id: 8,
  //       // suffix: '4',
  //       suffixColor: "bg-info text-dark-white",
  //       icon: Users,
  //       collapisble: true,
  //     },
  //     {
  //       title: "Chatbot Builder",
  //       href: "/chatbot-builder",
  //       id: 6,
  //       // suffix: '4',
  //       suffixColor: "bg-info text-dark-white",
  //       icon: bot,
  //       collapisble: true,
  //     },
  //   )
  // }

  // if (role === Roles.SUPER_ADMIN || role === Roles.COMPANY_ADMIN) {
  //   SidebarArr.push(
  //     // { caption: 'Home' },
  //     {
  //       title: "Channels",
  //       href: "/channels",
  //       id: 7,
  //       suffixColor: "bg-info text-dark-white",
  //       icon: Channels,
  //       collapisble: true,
  //     },
  //     {
  //       title: "Transactions",
  //       href: "/transactions",
  //       id: 13,
  //       // suffix: '4',
  //       suffixColor: "bg-info text-dark-white",
  //       icon: Transactions,
  //       collapisble: true,
  //     },
  //     // {
  //     //   title: "Conversations",
  //     //   href: "/conversations",
  //     //   id: 14,
  //     //   // suffix: '4',
  //     //   suffixColor: "bg-info text-dark-white",
  //     //   icon: Conversation,
  //     //   collapisble: true,
  //     // },
  //     {
  //       title: "Subscriptions",
  //       href: "/subscription-list",
  //       id: 12,
  //       // suffix: '4',
  //       suffixColor: "bg-info text-dark-white",
  //       icon: Subscribe,
  //       collapisble: true,
  //     },
      


  //   );
  // }

  // if (role === Roles.SUPER_ADMIN) {
  //   SidebarArr.push(
  //     // { caption: 'Home' },
  //     {
  //       title: "Customer Management",
  //       href: "/customer-management",
  //       id: 14,
  //       // suffix: '4',
  //       suffixColor: "bg-info text-dark-white",
  //       icon: Inbox,
  //       collapisble: true,
  //     },
  //     {
  //       title: "Login History",
  //       href: "/login-history",
  //       id: 14,
  //       // suffix: '4',
  //       suffixColor: "bg-info text-dark-white",
  //       icon: Inbox,
  //       collapisble: true,
  //     },
  //     {
  //       title: "Agency Details",
  //       href: "/agency-access",
  //       id: 14,
  //       // suffix: '4',
  //       suffixColor: "bg-info text-dark-white",
  //       icon: Inbox,
  //       collapisble: true,
  //     },
  //     {
  //       title: "Agency List",
  //       href: "/agency-list",
  //       id: 14,
  //       // suffix: '4',
  //       suffixColor: "bg-info text-dark-white",
  //       icon: Inbox,
  //       collapisble: true,
  //     },
  //     {
  //       title: "Chat List",
  //       href: "/agency-chat-list",
  //       id: 14,
  //       // suffix: '4',
  //       suffixColor: "bg-info text-dark-white",
  //       icon: Inbox,
  //       collapisble: true,
  //     },
  //     {
  //       title: "Lead List",
  //       href: "/agency-lead-list",
  //       id: 14,
  //       // suffix: '4',
  //       suffixColor: "bg-info text-dark-white",
  //       icon: Inbox,
  //       collapisble: true,
  //     }

  //   );
  // }

  // if (role === Roles.COMPANY_ADMIN || role === Roles.SUPER_ADMIN || role === Roles.COMPANY_MANAGER || role === Roles.AGENT) {
  //   SidebarArr.push({
  //     title: "Inbox",
  //     href: "/email",
  //     icon: Inbox,
  //     id: 10,
  //     collapisble: false,
  //   });
  // }


  const routes = [
    {
      title: "Dashboard",
      href: "/dashboard",
      id: 1,
      suffixColor: "bg-info text-dark-white",
      icon: Dashboard,
      collapisble: true,
      module: "Dashboard",
    },
    {
      title: "Chats",
      href: "/livechat",
      icon: Chat,
      id: 2,
      collapisble: false,
      module: "Chat",
    },
    {
      title: "Calendar",
      href: "/calendar",
      icon: Calendar,
      id: 3,
      collapisble: false,
      module: "Calendar",
    },
    {
      title: "Leads",
      href: "/leads",
      icon: Tickets,
      id: 4,
      collapisble: true,
      module: "Lead",
    },
    {
      title: "Contacts",
      href: "/contacts",
      icon: Contacts,
      id: 5,
      collapisble: false,
      module: "Contact",
    },
    {
      title: "Todo",
      href: "/todo",
      icon: Todo,
      id: 9,
      collapisble: false,
      module: "Todo",
    },
    {
      title: "Taskboard",
      href: "/taskboard",
      icon: Taskboard,
      id: 11,
      collapisble: false,
      module: "Taskboard",
    },
    {
      title: "Users",
      href: "/users",
      id: 8,
      suffixColor: "bg-info text-dark-white",
      icon: Users,
      collapisble: true,
      module: "Users",
    },
    {
      title: "Chatbot Builder",
      href: "/chatbot-builder",
      id: 6,
      suffixColor: "bg-info text-dark-white",
      icon: bot,
      collapisble: true,
      module: "ChatbotBuilder",
    },
    {
      title: "Channels",
      href: "/channels",
      id: 7,
      suffixColor: "bg-info text-dark-white",
      icon: Channels,
      collapisble: true,
      module: "Channels",
    },
    {
      title: "Transactions",
      href: "/transactions",
      id: 13,
      suffixColor: "bg-info text-dark-white",
      icon: Transactions,
      collapisble: true,
      module: "Transactions",
    },
    {
      title: "Subscriptions",
      href: "/subscription-list",
      id: 12,
      suffixColor: "bg-info text-dark-white",
      icon: Subscribe,
      collapisble: true,
      module: "Subscription",
    },
    {
      title: "Customer Management",
      href: "/customer-management",
      id: 14,
      suffixColor: "bg-info text-dark-white",
      icon: Inbox,
      collapisble: true,
      module: "CustomerManagement",
    },
    {
      title: "Login History",
      href: "/login-history",
      id: 15,
      suffixColor: "bg-info text-dark-white",
      icon: Inbox,
      collapisble: true,
      module: "LoginHistory",
    },
    {
      title: "Agency Details",
      href: "/agency-access",
      id: 16,
      suffixColor: "bg-info text-dark-white",
      icon: Inbox,
      collapisble: true,
      module: "AgencyAccess-",
    },
    {
      title: "Agencies",
      href: "/agency-list",
      id: 17,
      suffixColor: "bg-info text-dark-white",
      icon: Inbox,
      collapisble: true,
      module: "AgencyList",
    },
    {
      title: "Contract list",
      href: "/contract",
      id: 17,
      suffixColor: "bg-info text-dark-white",
      icon: Document,
      collapisble: true,
      module: "AgencyList",
    },
    {
      title: "Chat List",
      href: "/agency-chat-list",
      id: 18,
      suffixColor: "bg-info text-dark-white",
      icon: Inbox,
      collapisble: true,
      module: "AgencyChatList-",
    },
    {
      title: "Lead List",
      href: "/agency-lead-list",
      id: 19,
      suffixColor: "bg-info text-dark-white",
      icon: Inbox,
      collapisble: true,
      module: "AgencyLeadList-",
    },
    {
      title: "Inbox",
      href: "/email",
      icon: Inbox,
      id: 10,
      collapisble: false,
      module: "Inbox",
    },
  ];

  // Filter the routes based on the allowed modules
  const filteredRoutes = routes.filter((route) => allowedModules?.includes(route.module));

  return filteredRoutes;
};


export default SidebarData;
