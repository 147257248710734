import React, { useEffect } from "react";
import styles from "./Customer.module.css";
import { Col, Row, Table } from "reactstrap";
import moment from "moment";

import NoDataFound2 from "src/components/custom/NoDataFound2";
import useCustomerData from "./hooks/useCustomerData";
import Loader from "src/layouts/loader/Loader";
import NoContactFound from "src/assets/images/noDataFound/NoContactFound.png";
import NoTransactionFound from "src/assets/images/noDataFound/NoTransactionFound.png";

const CustomerDetails = ({ onClose, customerId }) => {
  const {
    fetchTransactions,
    fetchSubscription,
    transactionList,
    subscription,
    isLoading
  } = useCustomerData();

  useEffect(() => {
    fetchTransactions(customerId);
    fetchSubscription(customerId);
  }, // eslint-disable-next-line
  []);

  return (
    <div>
      <div className="container-fluid">
        <Row>
          <Col sm="12">
            <div className="py-3">
              <h4 className="fw-bolder d-flex align-items-center justify-content-between gap-3 mb-2 border-bottom pb-4">
                Subscription Details
                <span onClick={onClose} className="cursor-pointer">
                  x
                </span>
              </h4>
              <div></div>
              <div className="v-right-sidepoup">
                <div className="v-create-ticket-height">
                  <div style={{ minHeight: "250px" }}>
                    {isLoading ? (
                      <Loader size={"medium"} />
                    ) : subscription ? (
                      <>
                        <div className="border-bottom py-2">
                          <p className={styles.title + " mb-0"}>
                            Subscription ID{" "}
                          </p>
                          <h5 className="mb-0">{subscription?.subscription}</h5>
                        </div>
                        <div className="border-bottom py-2 d-flex">
                          <div className="w-50">
                            <p className={styles.title + " mb-0"}> Date</p>
                            <h5 className="mb-0">
                              {moment(subscription.created).format(
                                "DD/MM/YYYY"
                              )}
                            </h5>
                          </div>
                          <div className="w-50">
                            <p className={styles.title + " mb-0"}>Amount</p>
                            <h5 className="mb-0">£{subscription?.price}</h5>
                          </div>
                        </div>
                        <div className="border-bottom py-2 d-flex">
                          <div className="w-50">
                            <p className={styles.title + " mb-0"}>Name</p>
                            <h5 className="mb-0">{subscription?.usrName}</h5>
                          </div>
                          <div className="w-50">
                            {subscription?.email && (
                              <div className="">
                                <p className={styles.title + " mb-0"}>Email</p>
                                <h5 className="mb-0">{subscription?.email}</h5>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="border-bottom py-2 d-flex">
                          <div className="w-50">
                            <p className={styles.title + " mb-0"}>
                              Current Period Start
                            </p>
                            <h5 className="mb-0">
                              {moment(
                                subscription?.current_period_start
                              ).format("DD/MM/YYYY")}
                            </h5>
                          </div>
                          <div className="w-50">
                            <p className={styles.title + " mb-0"}>
                              Current Period End
                            </p>
                            <h5 className="mb-0">
                              {moment(subscription?.current_period_end).format(
                                "DD/MM/YYYY"
                              )}
                            </h5>
                          </div>
                        </div>
                        <div className="border-bottom py-2 d-flex">
                          <div className="w-50">
                            <p className={styles.title + " mb-0"}>
                              Billing Cycle
                            </p>
                            <h5 className="mb-0">
                              {moment(
                                subscription?.billing_cycle_anchor
                              ).format("DD/MM/YYYY")}
                            </h5>
                          </div>
                          <div className="w-50">
                            <p className={styles.title + " mb-0"}>Plan</p>
                            <h5 className="mb-0">{subscription?.subsName}</h5>
                          </div>
                        </div>
                      </>
                    ) : (
                      <NoDataFound2 width={"180px"} text="No Data Found!" image={NoContactFound} />
                    )}
                  </div>
                  <div className="mt-3">
                    <h4>Transactions :</h4>
                    <div className="table-responsive">
                      <Table className="align-middle">
                        <thead>
                          <tr>
                            <th>Sr.No.</th>
                            <th>Transaction Id</th>
                            <th>Amount</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        {isLoading ? (
                          <Loader size={"medium"} />
                        ) : transactionList && transactionList?.length > 0 ? (
                          <tbody>
                            {transactionList.map((transaction, index) => {
                              return (
                                <tr key={index + 1}>
                                  <td>{index + 1}</td>
                                  <td>{transaction.payment_intent}</td>

                                  <td>
                                    £
                                    {transaction?.price
                                      ? transaction?.price
                                      : 0}
                                  </td>
                                  <td>
                                    {moment(transaction?.created).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td colSpan="9">
                                <NoDataFound2 width={"180px"} text="No Data Found!" image={NoTransactionFound} />
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default CustomerDetails;
