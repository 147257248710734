import React, { useState } from "react";
import { FieldArray, Form, Formik } from "formik";
import * as Yup from "yup";
import AddIcon from '@mui/icons-material/Add';
import { ButtonLoader } from "src/components/forms/ButtonLoader";
import Style from "./AccountSetup2.module.css";
import { Table } from "reactstrap";
import TeamForm from "./TeamForm";
import Toast from "src/components/custom/Toast";
import Axios from "src/utils/axios";
import { useNavigate } from "react-router-dom";
import { updateFormStep, updateUser } from "src/store/AuthSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const TeamManagement = ({ handleError, branches, handleAgencyData, agency,closeError }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const userObj = useSelector((state) => state.auth.user);
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    team: Yup.array().of(
      Yup.lazy((value) => {
        if (!value.name && !value.email && !value.branch && !value.designation && !value.departmentType && !value.departmentRole) {
          return Yup.object().shape({});
        }
        return Yup.object().shape({
          name: Yup.string()
            .matches(/^[a-zA-Z ]+$/, "Name can only contain alphabetical characters.")
            .required("Name is required.")
            .test(
              "not-start-with-space",
              "Name cannot start with a space.",
              (value) => !value.startsWith(" ")
            ),
          email: Yup.string()
            .email("Invalid email format.")
            .required("Email is required.")
            .test("custom", "Please enter a valid email address.", (value) => {
              const parts = value.split("@");
              if (
                parts.length !== 2 ||
                /[^a-zA-Z0-9.-]/.test(parts[0]) ||
                /[^a-zA-Z0-9.-]/.test(parts[1])
              ) {
                return false;
              }

              if (parts.length === 2 && parts[1].split(".").length - 1 > 2) {
                return false;
              }
              return true;
            }),
          phone: Yup.string()
            .matches(/^\+?[0-9]\d{1,12}$/, "Invalid mobile number.")
            .min(10, "Phone number must be at least 10 characters.")
            .max(11, "Phone number cannot exceed 11 characters.")
            .required("Phone Number is required.")
            .test(
              "not-start-with-space",
              "Phone cannot start with a space.",
              (value) => {
                return !value || !value.startsWith(" ");
              }
            ),
          branch: Yup.string().required("Branch is required."),
          departmentType: Yup.string().required("Department is required."),
          departmentRole: Yup.string().required("Department Role is required."),
          designation: Yup.string().required("Designation is required."),
        });
      })
    ),
  });

  const initialValues = {
    team: Array.from({ length: branches }, () => ({
      name: "",
      email: "",
      phone: "",
      countryCode:"+44",
      branch: "",
      designation: "",
      departmentType: "",
      departmentRole: "",
    })),
  };

  const checkUniqueEmail =  async (team) => {
    const emails = team.map(member => member.email);
    const uniqueEmails = new Set(emails);
    const checkUnique = emails?.length === uniqueEmails?.size;
    return checkUnique;
  }

  const checkTeamArrayLength = (team) => {
    const assignedBranches = new Set();
  
    team?.forEach(member => {
      if (member?.branch?.trim() !== "") {
        assignedBranches?.add(member.branch.trim());
      }
    });

    // Check if the number of assigned branches is at least the number of branches
    return assignedBranches?.size >= branches;
  };

  const isFullyFilled = (team) => {
    return team.name && team.email && team.branch && team.departmentRole && team.departmentType && team.designation && team.phone;
  };

  const handleSubmit = async (values) => {
    const filledTeam = values.team.filter(isFullyFilled);
    
    const checkuniqueEmailasd = await checkUniqueEmail(filledTeam);
    if (!checkuniqueEmailasd) {
      handleError("Each team member must have a unique email.");
    }
    else if (!checkTeamArrayLength(values?.team)) {
      handleError("For agencies with multiple branches, please assign at least one agent/manager to each branch.");
    }
    else {
      try {
        setIsLoading(true);
        let response = await Axios.post(`/AccountSetup/create-manager-agent`, { team: filledTeam });
        handleAgencyData(response?.data?.data);
        
        const data = {
          ...userObj,
          // openingHours: response?.data?.openingHours,
          departmentType: response?.data?.departmentType,
          departmentRole: response?.data?.departmentRole,
          userCategory: response?.data?.newCategory,
          agencyCategory: response?.data?.agencyCategory,
          accountSetupFormStep: 3
        };
        dispatch(updateUser({ data: data }));
        // dispatch(updateFormStep(5))
        navigate('/chatbot-builder');
      } catch (error) {
        Toast(error, "error");
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="d-flex align-items-center " style={{ marginTop: "3rem", maxWidth: "90%", margin: "auto" }}>
      <div>
        <div className="w-50 mb-5">
          <h3 className={Style.question_heading + " mb-2"}>
            {" "}
            Add teammates to set up your account together{" "}
          </h3>
          <p className={Style.subHeading}>
            Share data, assign chats, leads, todo, and get more done together.
          </p>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, handleChange, setFieldValue, handleSubmit }) => (
            <Form>
              <FieldArray name="team">
                {({ push: pushDirector, remove: removeDirector }) => (
                  <div>
                    <div className={Style?.custom_table + " table-responsive "} style={{maxWidth:"90vw"}}>
                      <Table className={" align-middle mb-0"} style={{ borderRadius: "5px" }}>
                        <thead className={Style.agent_table_head}>
                          <tr>
                            <th>Full Name</th>
                            <th>Email Address</th>
                            <th className={Style.phone_input}>Phone Number</th>
                            <th>Branch</th>
                            <th>Designation</th>
                            <th>Department</th>
                            <th>Department Role</th>
                          </tr>
                        </thead>
                        <tbody>
                          <TeamForm
                            team={values.team}
                            errors={errors}
                            touched={touched}
                            handleChange={handleChange}
                            agency={agency}
                            setFieldValue={setFieldValue}
                            closeError={closeError}
                          />
                        </tbody>
                      </Table>
                    </div>
                    <div className="" style={{ marginLeft: "10px" }}>
                        <button type="button" className={Style.add_btn}
                          onClick={() => pushDirector({
                            name: "",
                            email: "",
                            phone:"",
                            countryCode:"+44",
                            branch: "",
                            designation: "",
                            departmentType: "",
                            departmentRole: ""
                          })}>
                          <AddIcon sx={{ color: "#933EFF", fontSize: "20px", cursor: "pointer" }} />
                          <p>Add Row</p>
                        </button>
                      </div>
                    <div className="d-flex align-items-center mt-5">
                      <ButtonLoader
                        onClickhandle={handleSubmit}
                        buttonName="Finish Setup"
                        isLoading={isLoading}
                        loaderColor={"white"}
                        className={Style.finish_btn}
                      // icon={<KeyboardArrowRightIcon />}
                      ></ButtonLoader>
                      
                    </div>
                  </div>
                )}
              </FieldArray>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default TeamManagement;
