import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./contractHeader";
import { useSelector } from "react-redux";
import { SocketProvider } from "src/store/context/socketContext";

const ContractLayout = () => {
    const topbarFixed = useSelector((state) => state.customizer.isTopbarFixed);
    return (<>
        <SocketProvider>
            <div className={`contentArea ${topbarFixed ? "fixedTopbar" : ""}`}>
                <Header />
                <Outlet />
            </div>
        </SocketProvider>
    </>)
}

export default ContractLayout;
