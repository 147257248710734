import React, { useEffect } from "react";
import {
  DropdownMenu,
  DropdownToggle,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from "reactstrap";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import * as Icon from "react-feather";
import { useSelector } from "react-redux";

const NavSubMenu = ({ icon, title, items, isUrl }) => {
  const location = useLocation();
  const topbarColor = useSelector((state) => state.customizer.topbarBg);

  const [collapsed, setCollapsed] = React.useState(false);
  const toggle = () => {
    setCollapsed(!collapsed);
  };
  useEffect(() => {
    if (isUrl) {
      setCollapsed(!collapsed);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleItemClick = () => {
    setCollapsed(false);
  };

  return (
    <UncontrolledDropdown
      className="parent_nav_item"
      isOpen={collapsed}
      toggle={toggle}
    >
      <DropdownToggle color={topbarColor} className="p-1 gap-2 ">
        <NavItem
          className={
            items.some((item) => location.pathname === item.href)
              ? "activeParent activeLink nav_item nav_item_width"
              : "nav_item nav_item_width"
          }
          onClick={() => {
            toggle();
          }}
        >
          <NavLink tag={Link} className="gap-1 nav_link d-flex">
            <span className="sidebarIcon">
              <img src={icon} alt=""></img>
            </span>
            <span className="hide-mini w-100">
              <div className="d-flex align-items-center">
                <span>{title}</span>
                <Icon.ChevronDown size={19} style={{ marginLeft: "3px" }} />
              </div>
            </span>
          </NavLink>
        </NavItem>
      </DropdownToggle>
      <DropdownMenu
        className=""
        style={{ boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.22)" }}
      >
        {items.map((item) => (
          <NavItem
            key={item.title}
            className={`hide-mini sub_item  ${
              location.pathname === item.href
                ? "activeSubLink nav_item"
                : "nav_item"
            }`}
          >
            <NavLink
              tag={Link}
              to={item.href}
              onClick={handleItemClick}
              className="gap-3 nav_link sub_link"
            >
              <span className="sidebarIcon">
                <img src={icon} alt=""></img>
              </span>
              <span className="hide-mini px-1">
                <span>{item.title}</span>
              </span>
            </NavLink>
          </NavItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
NavSubMenu.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
  icon: PropTypes.node,
  isUrl: PropTypes.bool,
  suffix: PropTypes.any,
  suffixColor: PropTypes.string,
};
export default NavSubMenu;
