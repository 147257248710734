import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import styles from "./contract.module.css"
import TextInput from "src/components/forms/TextInput";
import DateInput from "src/components/forms/DateInput";
import AstericSymbol from "src/components/custom/astericSymbol";
import Axios from "src/utils/axios";
import ErrorMsg from "src/components/custom/ErrorMsg";
import { useNavigate } from 'react-router-dom';
import subscribe from "src/assets/images/header/subscribe.svg"
import Toast from "src/components/custom/Toast";
import { noOfBrach } from "src/constants/formPicker";
import SelectWithSearch from "src/components/forms/SelectWithSearch";
import { FaQuestionCircle } from "react-icons/fa";

const Index = ({ previewData, setPreviewData, previewHtml }) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const validationSchema = Yup.object({
        contractDate: Yup.date().required("Contract Date is required"),
        directorName: Yup.string().trim().required("Director name is required.").test("not-start-with-space", "Customer name cannot start with a space.", (value) => { return !value || !value.startsWith(" "); }),
        customerName: Yup.string().trim().required("Customer name is required.").test("not-start-with-space", "Customer name cannot start with a space.", (value) => { return !value || !value.startsWith(" "); }),
        customerEmail: Yup.string().trim().required("Customer email is required.").email("Invalid email format."),
        agreementDate: Yup.date().required("End Date is required."),
        companyName: Yup.string().required("Company name is required."),
        companyNumber: Yup.string().required("Company number is required."),
        registeredOffice: Yup.string().required("Registred office is required."),
        noticePeriod: Yup.number(),
        trialPeriod: Yup.number(),
        setupFees: Yup.number().required("Setup fees is required."),
        totalSubsFees: Yup.number(),
        totalFees: Yup.number(),
        noOfbranch: Yup.number().required("No of branch is required."),
        branchFees: Yup.number().required("Branch fees is required."),
        partyDirector: Yup.string().required("Party Director is required."),
        isSendEmail: Yup.boolean().required("isSendEmail is requred.")

    });

    const formik = useFormik({
        initialValues: {
            contractDate: new Date(),
            directorName: "",
            customerName: "",
            customerEmail: "",
            agreementDate: new Date(),
            companyName: "",
            companyNumber: "",
            registeredOffice: "",
            noticePeriod: "",
            trialPeriod: "",
            setupFees: "",
            noOfUsers: "",
            perUserCost: "",
            totalSubsFees: "",
            totalFees: "",
            noOfbranch: "",
            branchFees: "",
            partyDirector: "",
            isSendEmail: false
        },
        validationSchema,
        validateOnChange: true,
        onSubmit: async (values) => {
            try {
                const payload = {
                    ...values,
                    totalSubsFees: ((parseFloat(formik.values.noOfbranch) * parseFloat(formik.values.branchFees))).toFixed(2),
                    totalFees: ((parseFloat(formik.values.noOfbranch) * parseFloat(formik.values.branchFees)) + (parseFloat(formik.values.setupFees))).toFixed(2)
                };
                setIsLoading(true);
                const contract = await Axios.post(`/contracts/listing/update-contract-sign`, { ...payload, signId: previewData._id, contractHtml: "" });
                setPreviewData(contract.data.data);
            } catch (error) {
                Toast(error, "error");
            }
            finally {
                setIsLoading(false);
                Toast("Contract updated successfully.", "success");
            }
        }
    });


    const handleDateChange = (date) => {
        formik.setFieldValue('contractDate', date);
    };

    const handleAgereMentDateChange = (date) => {
        formik.setFieldValue('agreementDate', date);
    };

    const handleCreateContractSign = async () => {
        try {
            const payload = {
                ...formik.values,
                totalSubsFees: ((parseFloat(formik.values.noOfbranch) * parseFloat(formik.values.branchFees))).toFixed(2),
                totalFees:  ((parseFloat(parseFloat(formik.values.setupFees).toFixed(2)) + parseFloat(formik.values.trialPeriod === 0 ? (parseFloat(formik.values.branchFees) * parseFloat(formik.values.noOfbranch)).toFixed(2) : 0)).toFixed(2))
            };
            setIsLoading(true);
            const contract = await Axios.post(`/contracts/listing/create-contract`, { contractId: previewData._id, ...payload, isSendEmail: true, contractHtml: "" });
            setPreviewData(contract.data.data);
        } catch (error) {
            Toast(error, "error");
        }
        finally {
            setIsLoading(false);
            Toast("Contract created and send to customer successfully", "success");
        }
    }

    const handleSubmit =async() =>{
        await formik.setFieldValue('isSendEmail', false);
        formik.handleSubmit();
    }

    useEffect(() => {
        if (previewData) {
            formik.setValues(previewData);
            previewData?.contractDate && formik.setFieldValue('contractDate', new Date(previewData?.contractDate));
            previewData?.agreementDate && formik.setFieldValue('agreementDate', new Date(previewData?.agreementDate));
            previewData && formik.setFieldValue('contractMasterId', previewData?.contractMasterId?._id);
        }
    }, [previewData])

    return <>

        <div className="contract_header row pt-3 pb-3 align-items-center">
            <div className="col-md-6">
                <h2 className={styles.title}>{previewData?.status !== 2 ? "Preview Contract" : "Contract Details"}</h2>
            </div>
            <div className="col-md-6 text-end">
                <button onClick={() => navigate("/eSignature/dashboard/contract")} className={styles.add_btn}> <VisibilityOutlinedIcon className="me-2 " />View Contracts</button>
            </div>
        </div>
        <div className={styles.contract_form + " mb-3"}>
            <div className="form_wrap " >
                <div className="row">
                    <div className={`col-md-6 mt-3 ${styles.reduce_grid_pad}`}>
                        <div className={styles.custom_select + "  " + styles.date_only}>
                            <label className={styles.edit_form_label}>Contract Created On<AstericSymbol /></label>
                            <DateInput
                                label={""}
                                name="contractDate"
                                customClass={"small-input w-100  "}
                                size={"small"}
                                value={formik.values.contractDate}
                                onChange={(date) => { handleDateChange(date) }}
                                disabled={previewData?.status === 2}
                            />
                            <ErrorMsg hasError={formik.touched.contractDate && formik.errors.contractDate} >
                                {formik.errors.contractDate}
                            </ErrorMsg>
                        </div>
                    </div>

                    <div className={`col-md-6 mt-3 ${styles.reduce_grid_pad}`}>
                        <div className="">
                            <label className={styles.edit_form_label}>Authorized Signatory (PropertyJinni)<AstericSymbol /></label>
                            <TextInput
                                customClass={styles.input_custom + " w-100 "}
                                type="text"
                                name="directorName"
                                variant="outlined"
                                placeholder="Diractor Name"
                                size={"small"}
                                value={formik.values.directorName}
                                onChange={formik.handleChange}
                                disabled={previewData?.status === 2}
                            />
                            <ErrorMsg hasError={formik.touched.directorName && formik.errors.directorName} >
                                {formik.errors.directorName}
                            </ErrorMsg>
                        </div>
                    </div>

                    <div className={`col-md-6 mt-3 ${styles.reduce_grid_pad}`} >
                        <div className="">
                            <label className={styles.edit_form_label}>Customer Name <AstericSymbol /></label>
                            <TextInput
                                customClass={styles.input_custom + " w-100 "}
                                type="text"
                                name="customerName"
                                variant="outlined"
                                placeholder="Customer Name"
                                size={"small"}
                                value={formik.values.customerName}
                                onChange={formik.handleChange}
                                disabled={previewData?.status === 2}
                            />
                            <ErrorMsg hasError={formik.touched.customerName && formik.errors.customerName} >
                                {formik.errors.customerName}
                            </ErrorMsg>

                        </div>
                    </div>

                    <div className={`col-md-6 mt-3 ${styles.reduce_grid_pad}`}>

                        <div className="">
                            <label className={styles.edit_form_label}>Customer Email <AstericSymbol /></label>
                            <TextInput
                                customClass={styles.input_custom + " w-100 "}
                                type="text"
                                name="customerEmail"
                                variant="outlined"
                                placeholder="Customer Email"
                                size={"small"}
                                value={formik.values.customerEmail}
                                onChange={formik.handleChange}
                                disabled={previewData?.status === 2}
                            />
                            <ErrorMsg hasError={formik.touched.customerEmail && formik.errors.customerEmail} >
                                {formik.errors.customerEmail}
                            </ErrorMsg>

                        </div>
                    </div>

                    <div className={`col-md-6 mt-3 ${styles.reduce_grid_pad}`}>
                        <div className={styles.custom_select + "  " + styles.date_only}>
                            <label className={styles.edit_form_label}>Agreement Date <AstericSymbol /></label>
                            <DateInput
                                label={""}
                                name="agreementDate"
                                customClass={"small-input w-100  "}
                                size={"small"}
                                value={formik.values.agreementDate}
                                onChange={(date) => handleAgereMentDateChange(date)}
                                disabled={previewData?.status === 2}
                            />
                            <ErrorMsg hasError={formik.touched.agreementDate && formik.errors.agreementDate} >
                                {formik.errors.agreementDate}
                            </ErrorMsg>
                        </div>
                    </div>

                    <div className={`col-md-6 mt-3 ${styles.reduce_grid_pad}`}>
                        <div className="">
                            <label className={styles.edit_form_label}>Company Name <AstericSymbol /></label>
                            <TextInput
                                customClass={styles.input_custom + " w-100 "}
                                type="text"
                                name="companyName"
                                variant="outlined"
                                placeholder="Company name"
                                size={"small"}
                                value={formik.values.companyName}
                                onChange={formik.handleChange}
                                disabled={previewData?.status === 2}
                            />
                            <ErrorMsg hasError={formik.touched.companyName && formik.errors.companyName}>
                                {formik.errors.companyName}
                            </ErrorMsg>
                        </div>
                    </div>

                    <div className={`col-md-6 mt-3 ${styles.reduce_grid_pad}`}>
                        <div className="">
                            <label className={styles.edit_form_label}>Company Number <AstericSymbol /></label>
                            <TextInput
                                customClass={styles.input_custom + " w-100 "}
                                type="text"
                                name="companyNumber"
                                variant="outlined"
                                placeholder="Company Number"
                                size={"small"}
                                value={formik.values.companyNumber}
                                onChange={formik.handleChange}
                                disabled={previewData?.status === 2}
                            />
                            <ErrorMsg hasError={formik.touched.companyNumber && formik.errors.companyNumber}>
                                {formik.errors.companyNumber}
                            </ErrorMsg>
                        </div>
                    </div>

                    <div className={`col-md-6 mt-3 ${styles.reduce_grid_pad}`}>
                        <div className="">
                            <label className={styles.edit_form_label}>Customer Registered Office Address<AstericSymbol /></label>
                            <TextInput
                                customClass={styles.input_custom + " w-100 "}
                                type="text"
                                name="registeredOffice"
                                variant="outlined"
                                placeholder="Customer Registered Office Address"
                                size={"small"}
                                value={formik.values.registeredOffice}
                                onChange={formik.handleChange}
                                disabled={previewData?.status === 2}
                            />
                            <ErrorMsg hasError={formik.touched.registeredOffice && formik.errors.registeredOffice}>
                                {formik.errors.registeredOffice}
                            </ErrorMsg>
                        </div>
                    </div>

                    <div className={`col-md-6 mt-3 ${styles.reduce_grid_pad}`}>
                        <div className="">
                            <label className={styles.edit_form_label}>Authorized Customer Signatory <AstericSymbol /></label>
                            <TextInput
                                customClass={styles.input_custom + " w-100 "}
                                type="text"
                                name="partyDirector"
                                variant="outlined"
                                placeholder="Customer Signatory"
                                size={"small"}
                                value={formik.values.partyDirector}
                                onChange={formik.handleChange}
                                disabled={previewData?.status === 2}
                            />
                            <ErrorMsg hasError={formik.touched.partyDirector && formik.errors.partyDirector}>
                                {formik.errors.partyDirector}
                            </ErrorMsg>
                        </div>
                    </div>
                    <div className={`col-md-6 mt-3 ${styles.reduce_grid_pad}`}>
                        <div className="">
                            <label className={styles.edit_form_label}>Notice period  (days)</label>
                            <TextInput
                                customClass={styles.input_custom + " w-100 "}
                                type="number"
                                name="noticePeriod"
                                variant="outlined"
                                placeholder="Notice Period"
                                size={"small"}
                                value={formik.values.noticePeriod}
                                onChange={formik.handleChange}
                                disabled={previewData?.status === 2}
                            />
                            <ErrorMsg hasError={formik.touched.noticePeriod && formik.errors.noticePeriod}>
                                {formik.errors.noticePeriod}
                            </ErrorMsg>
                        </div>
                    </div>
                    <div className={`col-md-6 mt-3 ${styles.reduce_grid_pad}`}>
                        <div className="">
                            <label className={styles.edit_form_label}>Trial Period (days) </label>
                            <TextInput
                                customClass={styles.input_custom + " w-100 "}
                                type="number"
                                name="trialPeriod"
                                variant="outlined"
                                placeholder="Trial Period"
                                size={"small"}
                                value={formik.values.trialPeriod}
                                onChange={formik.handleChange}
                                disabled={previewData?.status === 2}
                            />
                            <ErrorMsg hasError={formik.touched.trialPeriod && formik.errors.trialPeriod}>
                                {formik.errors.trialPeriod}
                            </ErrorMsg>
                        </div>
                    </div>

                    <div className={`col-md-6 mt-3 ${styles.reduce_grid_pad}`}>
                        <div className="">
                            <label className={styles.edit_form_label}>Setup Fees (one time) (<img src={subscribe} width={10} height={10} />) <AstericSymbol /></label>

                            <TextInput
                                customClass={styles.input_custom + " w-100 "}
                                type="number"
                                name="setupFees"
                                variant="outlined"
                                placeholder="Setup Fees"
                                size={"small"}
                                value={formik.values.setupFees}
                                onChange={formik.handleChange}
                                disabled={previewData?.status === 2}
                            />
                            <ErrorMsg hasError={formik.touched.setupFees && formik.errors.setupFees}>
                                {formik.errors.setupFees}
                            </ErrorMsg>
                        </div>
                    </div>


                    <div className={`col-md-6 mt-3 ${styles.reduce_grid_pad +" "+ styles.reduce_select }`}>
                        <div className="">
                            <label className={styles.edit_form_label}>No Of Branch  <AstericSymbol /></label>
                            <SelectWithSearch
                                variant="outlined"
                                name="noOfbranch"
                                options={noOfBrach}
                                value={formik.values.noOfbranch}
                                onChange={(e, obj) => {
                                    formik.setFieldValue("noOfbranch", obj);
                                }}
                                size={"small"}
                                noOptionsText={"No Of Branch"}
                                disabled={previewData?.status === 2}
                            />
                            <ErrorMsg hasError={formik.touched.noOfbranch && formik.errors.noOfbranch}>
                                {formik.errors.noOfbranch}
                            </ErrorMsg>
                        </div>
                    </div>

                    <div className={`col-md-6 mt-3 ${styles.reduce_grid_pad}`}>
                        <div className="">
                            <label className={styles.edit_form_label}>Per Branch Fees (<img src={subscribe} width={10} height={10} />)<AstericSymbol /> </label>

                            <TextInput
                                customClass={styles.input_custom + " w-100 "}
                                type="number"
                                name="branchFees"
                                variant="outlined"
                                placeholder="Branch fees"
                                size={"small"}
                                value={formik.values.branchFees}
                                onChange={formik.handleChange}
                                disabled={previewData?.status === 2}
                            />
                            <ErrorMsg hasError={formik.touched.totalSubsFees && formik.errors.branchFees}>
                                {formik.errors.branchFees}
                            </ErrorMsg>
                        </div>
                    </div>

                    <div className={`col-md-6 mt-3 ${styles.reduce_grid_pad}`}>
                        <div className="">

                            <label className={styles.edit_form_label}>Monthly Subscription Fees (<img src={subscribe} width={10} height={10} />) </label>

                            <TextInput
                                disabled={true}
                                customClass={styles.input_custom + " w-100 "}
                                type="number"
                                name="totalSubsFees"
                                variant="outlined"
                                placeholder="Monthly Subscription Fees"
                                size={"small"}
                                value={((parseFloat(formik.values.noOfbranch) * parseFloat(formik.values.branchFees))).toFixed(2)}
                            // onChange={formik.handleChange}
                            />

                            <ErrorMsg hasError={formik.touched.totalSubsFees && formik.errors.totalSubsFees}>
                                {formik.errors.totalSubsFees}
                            </ErrorMsg>
                        </div>
                    </div>

                    <div className={`col-md-6 mt-3 ${styles.reduce_grid_pad}`}>
                        <div className="">

                            <label className={styles.edit_form_label + " " + styles.custom_tooltip + " " + styles.hover_12}>To Be Paid Today (<img src={subscribe} width={10} height={10} />) <AstericSymbol />
                                {/* <FaQuestionCircle style={{ marginLeft: "5px" }} />
                                <div className={styles.tooltip_12}>This payment will be charged when the trial period is over.</div> */}
                            </label>
                            <TextInput
                                disabled={true}
                                customClass={styles.input_custom + " w-100 "}
                                type="number"
                                name="totalFees"
                                variant="outlined"
                                placeholder="To be paid today"
                                size={"small"}
                                value={((parseFloat(parseFloat(formik.values.setupFees).toFixed(2)) + parseFloat(formik.values.trialPeriod === 0 ? (parseFloat(formik.values.branchFees) * parseFloat(formik.values.noOfbranch)).toFixed(2) : 0)).toFixed(2))}
                            />

                            <ErrorMsg hasError={formik.touched.totalFees && formik.errors.totalFees}>
                                {formik.errors.totalFees}
                            </ErrorMsg>
                        </div>
                    </div>



                    {previewData?.status === 0 &&
                        <div className="col-12 text-end mt-3">
                            <button disabled={isLoading} onClick={() => {handleSubmit(); }} type="button" className={styles.form_submit_btn + " btn   " + styles.create_contract}  > Update </button>
                            <button disabled={isLoading} onClick={() => { handleCreateContractSign(); }} type="button" className={styles.form_submit_btn + " btn   " + styles.create_contract + " " + styles.alt_btn}  > Create & Send Email</button>
                        </div>}
                </div>
            </div>
        </div>

    </>
}

export default Index;