import React, { useState, useEffect } from "react";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Style from "./AccountSetup2.module.css";
import WorkingHours from "./WorkingHours";
import TagsInput from "src/components/apps/tags/TagsInput";
import { ButtonLoader } from "src/components/forms/ButtonLoader";
import BranchDetailForm from "./BranchDetailForm";
import Axios from "src/utils/axios";
import Toast from "src/components/custom/Toast";
import ErrorMsg from "src/components/custom/ErrorMsg";
import moment from "moment";
import { useNavigate } from "react-router-dom";
 
const BranchDetailCard = ({
  index,
  totalCount,
  handleNext,
  isDisabled,
  handleBranchSubmit,
  currentBranch,
  handleAgencyData,
  agency,
  branch,
  handleOpenBranch,
  singleBranch,
  handleBranchList,
  getBranch,
  isEdit,
  website,
  handleWebsite
}) => {

  const navigate=useNavigate();
  const isBranchInAgency = index < (agency?.branches?.length || 0);

  const [isDetailSection, setIsDetailSection] = useState(true);
  const [isLocationSection, setIsLocationSection] = useState(false);
  const [isHourSection, setIsHourSection] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [collapsed, setCollapsed] = useState(isBranchInAgency ? true : false);

  const checkOverlappingDays = (workingHours) => {
    const daysMap = {
      "everyday": ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"],
      "mon-fri": ["monday", "tuesday", "wednesday", "thursday", "friday"],
      "sat-sun": ["saturday", "sunday"]
    };
 
    const selectedDays = new Set();
 
    for (const hours of workingHours) {
      const { day } = hours;
      if (day in daysMap) {
        for (const mappedDay of daysMap[day]) {
          if (selectedDays.has(mappedDay)) {
            return false; // Overlapping day found
          }
          selectedDays.add(mappedDay);
        }
      } else {
        if (selectedDays.has(day)) {
          return false; // Overlapping day found
        }
        selectedDays.add(day);
      }
    }
 
    return true;
  };
 
  const validationSchema = Yup.object().shape({
    branchName: Yup.string()
      .required("Branch name is required.")
      .test("not-start-with-space", "Branch name cannot start with a space.", (value) => !value.startsWith(" ")),
    branchWebsite: Yup.string().test("is-valid-url", "Invalid URL format.", (value) => {
      if (!value) {
        return true;
      }
      return /^(?:https?:\/\/)?(?:www\.)?[\w-]+(?:\.[a-z]{2,})(?:\.[a-z]{2,})?(?:\.[a-z]{2,})?(?:\.[a-z]{2,})?(?:\.[a-z]{2,})?$/i.test(value);
    }),
    dealsWith: Yup.string().required("Deals with is required."),
    typeOfAgency: Yup.string().required("Type of agency is required."),
    workingHours: Yup.array().of(
      Yup.object().shape({
        day: Yup.string().required("Day is required."),
        opening: Yup.string()
          .nullable()
          .test("is-valid-opening", "Opening time is required if closing time is provided.", function (value) {
            const { closing } = this.parent;
            if (closing && !value) {
              return false;
            }
            return true;
          }),
        closing: Yup.string()
          .nullable()
          .test("is-after-opening", "Closing time must be after opening time.", function (value) {
            const { opening } = this.parent;
            if (!opening || !value) return true; // Skip validation if either time is missing
            return moment(value, "HH:mm").isAfter(moment(opening, "HH:mm"));
          })
          .test("is-valid-closing", "Closing time is required if opening time is provided.", function (value) {
            const { opening } = this.parent;
            if (opening && !value) {
              return false;
            }
            return true;
          }),
      })
    )
      .min(1, "At least one working hours entry is required.")
      .test("no-overlapping-days", "There are overlapping days in the working hours.", (workingHours) => checkOverlappingDays(workingHours)),
    displayLoc: Yup.array().min(1, "At least one postal code is required."),
  });
 
  const initialValues = {
    ...(isBranchInAgency
      ? {
        branchName: agency?.branches[index]?.branchName || "",
        branchWebsite: agency?.branches[index]?.branchWebsite || "",
        dealsWith: agency?.branches[index]?.dealsWith ? agency?.branches[index]?.dealsWith?.length > 1 ? "both" : agency?.branches[index]?.dealsWith[0] : "residential",
        typeOfAgency: agency?.branches[index]?.typeOfAgency ? agency?.branches[index]?.typeOfAgency?.length > 1 ? "both" : agency?.branches[index]?.typeOfAgency[0] : "both",
        displayLoc: agency?.branches[index]?.servingLocation?.displayLoc || [],
        workingHours: agency?.branches[index]?.workingHours || [],
      }
      : {
        branchName: "",
        branchWebsite: "",
        dealsWith: "residential",
        typeOfAgency: "both",
        displayLoc: [],
        workingHours: [
          { day: "", opening: "", closing: "", },
        ],
      }),
  };

  
 
  const handleSubmit = async (values) => {
    
    setIsLoading(true);

    try {

      if(isEdit){
        navigate("/users?branch=created")
      }

      console.log('website',website);
      
      
      if (!website) {
        console.log("abc");
        
        handleWebsite();
        setIsLoading(false); 
        return;
      }
      
      let response = await Axios.post(`/AccountSetup/${isBranchInAgency ? 'update-branch' : 'create-branch'}`, { ...values, _id: agency?.branches[index]?._id, ...(singleBranch && {singleBranch:singleBranch}) });
     
      if(singleBranch && response?.data?.message==="Branch created successfully"){
        handleBranchList();
        return
      }
     
      if(singleBranch){
        handleNext();
        return;
      }
      else{
        handleAgencyData(response?.data?.data);
        setCollapsed(true);
        setIsLoading(false);
        if (totalCount - 1 === index) {
          handleNext();
        } else {
          handleBranchSubmit();
        }
      }
    } catch (error) {
      Toast(error, "error");
      setIsLoading(false);
    }
  };
 

 
  const handleTagsChange = async (tags, setFieldValue, values, type) => {
    try {
      // Generate new entries based on the new tags
      const newEntries = tags.map(tag => ({
        locValue: tag, locType: type,
        displayPostCode: type === 'zip' ? [tag] : [],
      }));
 
      let responseData = await Axios.post('/postCode/validate', { "postCode": newEntries[newEntries.length - 1]?.locValue, "isPostCode": newEntries[newEntries.length - 1]?.locType === "zip" })
      newEntries[newEntries.length - 1].displayPostCode = responseData?.data?.postCodes
 
      if (responseData?.data?.postCodes?.length > 0) {
        // Combine existing entries with new entries
        const updatedDisplayLoc = [
          ...values.displayLoc.filter(loc => loc.locType !== type), // Remove old entries of the same type
          ...newEntries // Add new entries
        ];
 
        // Update Formik's state
        setFieldValue("displayLoc", updatedDisplayLoc);
      }
      else {
        Toast(`This ${type === 'zip' ? "post code" : "location name"} is not valid, please enter again.`, "error")
      }
 
    } catch (error) {
      Toast()
    }
  };
 
  const handleDelete = (item, type, setFieldValue, values) => {
    // Filter out the tag to be removed
    const updatedDisplayLoc = values.displayLoc.filter(
      loc => !(loc.locValue === item && loc.locType === type)
    );
 
    // Update Formik's state with the new array
    setFieldValue("displayLoc", updatedDisplayLoc);
  };
 
  // Helper function to check if any working hours entry has empty fields
  const hasIncompleteWorkingHours = (workingHours) => {
    return workingHours?.every(({ day, opening, closing }) => day && opening && closing);
  };
 
  const handleCurrentBranch = () => {
    handleOpenBranch(index);
  }
 
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, handleChange, setFieldValue,handleSubmit }) => (
        <Form>
          <div key={index} className={Style.branch_card} style={{...(index < currentBranch ? { border: "1px solid #00A7BB" } : {}), ...(singleBranch ? { boxShadow:"none" } : {}) }}>
            {isBranchInAgency ?
              <div className="d-flex align-items-center" onClick={() => { handleCurrentBranch(); setCollapsed(!collapsed) }} >
                <CheckCircleIcon sx={{ fontSize: "27px", marginRight: "8px", color: "#00A7BB" }} />
                <div className={Style.branch_index} style={{ textTransform: "capitalize" }}>{values.branchName}</div>
              </div>
              :
              <div className="d-flex align-items-center" onClick={() => { setCollapsed(!collapsed) }}>
                <CheckCircleIcon sx={{ fontSize: "27px", marginRight: "8px", color: "#E3E3E5" }} />
                <div className={Style.branch_index}> Branch {singleBranch ? "" : index + 1 + " of " + totalCount}{" "} </div>
              </div>
            }
            {((isBranchInAgency && !collapsed) || !isDisabled) && <div className={Style.branch_details}>
              <div className={Style.first_section}>
                <div className="d-flex align-items-start">
                  {(values?.branchName && values?.dealsWith && values?.typeOfAgency && !errors?.branchName && !errors?.dealsWith && !errors?.typeOfAgency) ?
                    <CheckCircleIcon sx={{ fontSize: "27px", marginRight: "8px", color: "#00A7BB" }} />
                    :
                    <CheckCircleIcon sx={{ fontSize: "27px", marginRight: "8px", color: "#E3E3E5" }} />}
                  <div className="mt-1 w-100">
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <h6 className={Style.detail_heading}>Branch Details</h6>
                        <p>Add your basic branch details</p>
                      </div>
                      <ExpandMoreIcon sx={{ fontSize: "40px", color: "#4C5D70", cursor: "pointer", ...(isDetailSection && { transform: "rotate(180deg)" }), }}
                        onClick={() => { setIsDetailSection(!isDetailSection); }}
                      />
                    </div>
                    {isDetailSection && (
                      <BranchDetailForm
                        errors={errors}
                        touched={touched}
                        values={values}
                        handleChange={handleChange}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className={Style.second_section}>
                <div className="d-flex align-items-start">
                  {(values?.displayLoc?.length > 0) ? <CheckCircleIcon sx={{ fontSize: "27px", marginRight: "8px", color: "#00A7BB" }} />
                    : <CheckCircleIcon sx={{ fontSize: "27px", marginRight: "8px", color: "#E3E3E5" }} />}
                  <div>
                    <div className="d-flex align-items-center justify-content-between mt-1 w-100">
                      <div className="w-75">
                        <h6 className={Style.detail_heading}> Branch Serving Location </h6>
                        <p>
                          Tell us where your branch serves to help us understand
                          your service area by entering the postcodes (zip
                          codes) or names of the areas your branch covers
                        </p>
                      </div>
                      <ExpandMoreIcon
                        sx={{
                          fontSize: "40px",
                          color: "#4C5D70",
                          cursor: "pointer",
                          ...(isLocationSection && { transform: "rotate(180deg)" }),
                        }}
                        onClick={() => { setIsLocationSection(!isLocationSection); }}
                      />
                    </div>
                    {isLocationSection && (
                      <div className="">
                        <ErrorMsg hasError={touched.displayLoc && errors.displayLoc}>{errors.displayLoc} </ErrorMsg>
                        <div className="">
                          <label>
                          Enter a Postcode
                          </label>
                          <TagsInput
                            tags={values.displayLoc.filter(loc => loc.locType === 'zip').map(loc => loc.locValue)}
                            setTags={(tags) => handleTagsChange(tags, setFieldValue, values, "zip")}
                            fullWidth
                            variant="outlined"
                            id="tags"
                            name="tags"
                            placeholder="e.g. UB10 , UB*"
                            multiline={true}
                            rows={3}
                            customClass={Style.tag_box}
                            handleDelete={(item) => handleDelete(item, "zip", setFieldValue, values)}
                          />
                        </div>
                        <div className={Style.or_label}>or</div>
                        <div className="">
                          <label>Enter a Location</label>
                          <TagsInput
                            tags={values.displayLoc.filter(loc => loc.locType === 'loc').map(loc => loc.locValue)}
                            setTags={(tags) => handleTagsChange(tags, setFieldValue, values, "loc")}
                            fullWidth
                            variant="outlined"
                            id="tags"
                            name="tags"
                            placeholder="e.g. Wembley"
                            multiline={true}
                            rows={3}
                            customClass={Style.tag_box}
                            handleDelete={(item) => handleDelete(item, "loc", setFieldValue, values)}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className={Style.third_section}>
                <div className="d-flex align-items-start">
                  {(hasIncompleteWorkingHours(values?.workingHours)) ? <CheckCircleIcon sx={{ fontSize: "27px", marginRight: "8px", color: "#00A7BB" }} /> :
                    <CheckCircleIcon sx={{ fontSize: "27px", marginRight: "8px", color: "#E3E3E5" }} />}
                  <div className="w-100">
                    <div className="d-flex align-items-center justify-content-between mt-1 w-100">
                      <div className="w-75">
                        <h6 className={Style.detail_heading}>Working hours</h6>
                        <p> Communications will only be sent to this branch during these hours. </p>
                      </div>
                      <ExpandMoreIcon
                        sx={{ fontSize: "40px", color: "#4C5D70", cursor: "pointer", ...(isHourSection && { transform: "rotate(180deg)" }), }}
                        onClick={() => { setIsHourSection(!isHourSection); }}
                      />
                    </div>
                    {isHourSection && (
                      <div>
                        <FieldArray name="workingHours">
                          {({ push: pushWorkingHours, remove: removeWorkingHours }) => (
                            <WorkingHours
                              pushWorkingHours={pushWorkingHours}
                              removeWorkingHours={removeWorkingHours}
                              workingHours={values?.workingHours}
                              touched={touched}
                              errors={errors}
                              handleChange={handleChange}
                            />
                          )}
                        </FieldArray>
                        <div className="text-end mb-3">
                          <ButtonLoader
                            onClickhandle={handleSubmit}
                            buttonName="Save"
                            isLoading={isLoading}
                            loaderColor={"white"}
                            style={{
                              marginLeft: "12px",
                              paddingLeft: "12px",
                              paddingRight: "12px",
                            }}
                            className={Style.save_btn}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>}
          </div>
        </Form>
      )}
    </Formik>
  );
};
 
export default BranchDetailCard;